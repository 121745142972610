import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { option1, option11, option2, option3, option31, option4, option41, option5, option6, option7 } from '@/units';
import { option8, option9 } from '@/units/chartMap';
import 'swiper/css';
let migrateChart;
export default {
  name: 'index',
  components: {
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      powerChart: null,
      //供电系统数据统计
      airChart: null,
      //压风系统数据统计
      centralPumpChart: null,
      //中央泵房
      monthEveng1: null,
      //本月事
      monthEveng2: null,
      //本月事
      poweropts: {},
      equipmentActiveNum: 0,
      modules: [Autoplay],
      list: [{
        yuanmei: '523',
        putongmei: '134',
        jingmei: '486'
      }, {
        yuanmei: '215',
        putongmei: '120',
        jingmei: '130'
      }, {
        yuanmei: '523',
        putongmei: '134',
        jingmei: '486'
      }, {
        yuanmei: '215',
        putongmei: '120',
        jingmei: '130'
      }],
      moveNum: 0,
      moveTimer: null,
      classOption: {
        singleHeight: 30,
        // 元素高度+下边距
        stop: false,
        // 是否停止滚动
        autoPlay: false,
        // 不自动滚动
        waitTime: 1000 // 停顿时间

      },
      classOption2: {
        step: 0.5,
        // 速度
        hoverStop: true,
        // 鼠标停止
        direction: 1 // 0 下 1 上 2 左 3 右

      }
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.powerChart = this.$echarts.init(document.getElementById('powerChart'));
      this.airChart = this.$echarts.init(document.getElementById('airChart'));
      this.centralPumpChart = this.$echarts.init(document.getElementById('centralPumpChart'));
      this.monthEveng1 = this.$echarts.init(document.getElementById('monthEveng1'));
      this.monthEveng2 = this.$echarts.init(document.getElementById('monthEveng2'));
      migrateChart = this.$echarts.init(document.getElementById('migrateChart'));
      this.equipmentChart1 = this.$echarts.init(document.getElementById('equipmentChart1'));
      this.equipmentChart2 = this.$echarts.init(document.getElementById('equipmentChart2'));
      this.equipmentChart3 = this.$echarts.init(document.getElementById('equipmentChart3'));
      this.CTVchart = this.$echarts.init(document.getElementById('CTVchart'));
      this.coalMines1 = this.$echarts.init(document.getElementById('coalMines1'));
      this.coalMines2 = this.$echarts.init(document.getElementById('coalMines2'));
      this.powerChart.setOption(option1);
      this.airChart.setOption(option2);
      this.centralPumpChart.setOption(option11);
      this.monthEveng1.setOption(option3);
      this.monthEveng2.setOption(option31);
      this.coalMines1.setOption(option4);
      this.coalMines2.setOption(option41);
      this.equipmentChart1.setOption(option5());
      this.equipmentChart2.setOption(option6());
      this.equipmentChart3.setOption(option7());
      migrateChart.setOption(option8);
      this.CTVchart.setOption(option9);
      this.moveFn();
    });
  },

  methods: {
    equipmentFn(num) {
      this.equipmentActiveNum = num;
      let num5 = Math.random() * 100;
      let num6 = Math.random() * 100;
      let num7 = Math.random() * 100;
      this.equipmentChart1.setOption(option5(num5));
      this.equipmentChart2.setOption(option6(num6));
      this.equipmentChart3.setOption(option7(num7));
    },

    moveFn() {
      this.moveTimer = setInterval(() => {
        this.moveNum++;

        if (this.moveNum >= 30) {
          let one = this.list.shift();
          this.list.push(one);
          this.moveNum = 0;
        }
      }, 100);
    },

    stopMove() {
      clearInterval(this.moveTimer);
    },

    onChange(e) {},

    SingScrollEnd(e) {},

    migrateClick(el) {
      if (el.target.className == 'automation') {
        window.open('https://zk.nanyizk.com/');
      } else if (el.target.className == 'information') {
        window.open('/#/information');
      }
    }

  }
};