import {
    createRouter,
    createWebHistory,
    createWebHashHistory
} from 'vue-router'
import index from '../views/index.vue'

const routes = [{
        path: '/',
        name: 'index',
        component: index
    },
    {
        path: '/viewer',
        name: 'modelViewer',
        component: () =>
            import ('../views/ModelViewer.vue')
    },
    {
        path: '/riskWarning',
        name: 'riskWarning',
        component: () =>
            import ('../views/riskWarning.vue')
    },
    {
        path: '/riskWarningCopy',
        name: 'riskWarningCopy',
        component: () =>
            import ('../views/riskWarningCopy.vue'),
        meta: {
            title: '安全风险视觉AI分析系统'
        }
    },
    {
        path: '/safeProduction',
        name: 'safeProduction',
        component: () =>
            import ('../views/safeProduction.vue'),
        meta: {
            title: '智能化园区与经营管理系统'
        }
    },
    {
        path: '/GisView',
        name: 'GisView',
        component: () =>
            import ('../views/GisView.vue'),
        meta: {
            title: '环境风险与应急调度系统'
        }
    },
    {
        path: '/digitalTwin',
        name: 'digitalTwin',
        component: () =>
            import ('../views/digitalTwin.vue'),
        meta: {
            title: '三维矿山数字孪生'
        }
    },
    {
        path: '/information',
        name: 'information',
        component: () =>
            import ('../views/information.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () =>
            import ('../views/test.vue'),
        meta: {
            title: '煤矿安全风险大数据分析系统'
        }
    }
]

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router