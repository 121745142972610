import * as echarts from 'echarts'
import moment from 'moment'
moment.locale('zh-cn');
//交通流量
var data = {
    id: 'multipleBarsLines',
    legendBar: ['电压', '电流'],
    symbol: ' ', //数值是否带百分号        --默认为空 ''
    legendLine: ['环比', '同比'],
	xAxis: [moment().subtract(6, 'months').format('YYYY/MM'), moment().subtract(5, 'months').format('YYYY/MM'), moment().subtract(4, 'months').format('YYYY/MM'), moment().subtract(3, 'months').format('YYYY/MM'), moment().subtract(2, 'months').format('YYYY/MM'), moment().subtract(1, 'months').format('YYYY/MM'), moment().format('YYYY/MM')],
    yAxis: [
        [500, 409, 523, 601, 562, 496, 460]
    ],
    lines: [
        [10, 10, 9, 11, 7, 4]
    ],
    barColor: ['#00E5C4', '#FFAF21'], //柱子颜色 必填参数
    barTranColor: ['rgba(0,229,196,0)', 'rgba(255,175,33,0)'], //柱子颜色 必填参数
    lineColor: ['#fd6665', '#21C9FF'], // 折线颜色

}

var myData = (function test() {
    let yAxis = data.yAxis || []
    let lines = data.lines || []
    let legendBar = data.legendBar || []
    let legendLine = data.legendLine || []
    var symbol = data.symbol || ' '
    let seriesArr = []
    let legendArr = []
    yAxis && yAxis.forEach((item, index) => {
        legendArr.push({
            name: legendBar && legendBar.length > 0 && legendBar[index]
        })
        seriesArr.push({
            name: '用电量',
            type: 'bar',
            barGap: '0.5px',
            data: item,
            barWidth: data.barWidth || 12,
            label: {
                show: false,
                formatter: '{c}' + symbol,
                position: 'top',
                color: '#000',
                fontStyle: 'normal',
                fontFamily: '微软雅黑',
                textAlign: 'left',
                fontSize: 11,
            },
            itemStyle: { //图形样式
                borderRadius:0,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: data.barColor[index] // 0% 处的颜色
                        },
                        {
                            offset: 1,
                            color: data.barTranColor[index]// 100% 处的颜色
                        }
                    ],
                    global: false // 缺省为 false
                }
            }
        })
    })

    // lines && lines.forEach((item, index) => {
    //     legendArr.push({
    //         name: legendLine && legendLine.length > 0 && legendLine[index]
    //     })
    //     seriesArr.push({
    //         name: legendLine && legendLine.length > 0 && legendLine[index],
    //         type: 'line',
    //         data: item,
    //         itemStyle: {
    //             color: data.lineColor[index],
    //             lineStyle: {
    //                 width: 1,//折线宽度
    //                 type: 'solid',
    //             }
    //         },
    //         label: {
    //             show: false, //折线上方label控制显示隐藏
    //             position: 'top'
    //         },
    //         symbol: 'circle',
    //         symbolSize: 5
    //     })
    // })

    return {
        seriesArr,
        legendArr
    }
})()
export let option1 = {
    title: {
        show: true,
        text: data.title,
        subtext: data.subTitle,
        link: '1111'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{b}：{c}(万度)'
    },
    // legend: {
    //     right: data.legendRight || '30%',
    //     top: 0,
    //     right:10,
    //     itemGap: 16,
    //     itemWidth: 10,
    //     itemHeight: 10,
    //     data: myData.legendArr,
    //     textStyle: {
    //         color: '#fff',
    //         fontStyle: 'normal',
    //         fontFamily: '微软雅黑',
    //         fontSize: 12,
    //     }
    // },
    grid: {
        x: 0,
        y: 30,
        x2: 0,
        y2: 25,
    },
    xAxis: {
        type: 'category',
        data: data.xAxis,
        axisTick: {
            show: false,
        },

        axisLine: {
            show: false,
        },
        axisLabel: {       //轴标
            show: true,
            interval: '0',
            lineHeight:5,
            padding: [2, 2, 0, 2],
            height: 50,
            fontSize: 12,
            color:'#fff',
            rich: {
                Sunny: {
                    height: 50,
                    // width: 60,
                    padding: [0, 5, 0, 5],
                    align: 'center',
                },
            },
            // formatter: function(params, index) {
            //     var newParamsName = "";
            //     var splitNumber = 5;
            //     var paramsNameNumber = params && params.length;
            //     if (paramsNameNumber && paramsNameNumber <= 4) {
            //         splitNumber = 4;
            //     } else if (paramsNameNumber >= 5 && paramsNameNumber <= 7) {
            //         splitNumber = 4;
            //     } else if (paramsNameNumber >= 8 && paramsNameNumber <= 9) {
            //         splitNumber = 5;
            //     } else if (paramsNameNumber >= 10 && paramsNameNumber <= 14) {
            //         splitNumber = 5;
            //     } else {
            //         params = params && params.slice(0, 15);
            //     }

            //     var provideNumber = splitNumber; //一行显示几个字
            //     var rowNumber = Math.ceil(paramsNameNumber / provideNumber) || 0;
            //     if (paramsNameNumber > provideNumber) {
            //         for (var p = 0; p < rowNumber; p++) {
            //             var tempStr = "";
            //             var start = p * provideNumber;
            //             var end = start + provideNumber;
            //             if (p == rowNumber - 1) {
            //                 tempStr = params.substring(start, paramsNameNumber);
            //             } else {
            //                 tempStr = params.substring(start, end) + "\n";
            //             }
            //             newParamsName += tempStr;
            //         }

            //     } else {
            //         newParamsName = params;
            //     }
            //     params = newParamsName
            //     return '{Sunny|' + params + '}';
            // },
            color: '#687284',
        },

    },
    yAxis: {
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#57617B',
                type: 'solid'
            },
            interval: 2
        },
        splitNumber: 4,
    },
    series: myData.seriesArr
}
// 瓦斯产量统计
export let option11 = {
    tooltip: {
        formatter: '{a} <br/>{b}: {c}'
    },
    grid: {
        left: 0,
        right: 0,
        top: 8,
        bottom: '20%',
    },
    xAxis: {
        type: 'category',
        data: [moment().subtract(6, 'months').format('YYYY/MM'), moment().subtract(5, 'months').format('YYYY/MM'), moment().subtract(4, 'months').format('YYYY/MM'), moment().subtract(3, 'months').format('YYYY/MM'), moment().subtract(2, 'months').format('YYYY/MM'), moment().subtract(1, 'months').format('YYYY/MM'), moment().format('YYYY/MM')]
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            name: '瓦斯产量',
            type: 'bar',
            barWidth: 12,
            label: {
                show: false,
                formatter: '{c}',
                position: 'top',
                color: '#000',
                fontStyle: 'normal',
                fontFamily: '微软雅黑',
                textAlign: 'left',
                fontSize: 11,
            },
            itemStyle: { //图形样式
                borderRadius:0,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: '#89BD1B' // 0% 处的颜色
                        },
                        {
                            offset: 1,
                            color: 'rgba(255,175,33,0)'// 100% 处的颜色
                        }
                    ],
                    global: false // 缺省为 false
                }
            },
            data:[20,50,60,70,86,126,132]
        }
    ]
};


//设备智能化统计
export let option2 = {
    
    tooltip: {//鼠标指上时的标线
        trigger: 'axis',
        axisPointer: {
            lineStyle: {
                color: '#fff'
            }
        }
    },
    legend: {
        icon: 'rect',
        itemWidth: 14,
        itemHeight: 5,
        itemGap: 13,
        data: ['小型设备', '中型设备', '大型设备'],
        right: '10px',
        top: '0px',
        textStyle: {
            fontSize: 12,
            color: '#fff'
        }
    },
    grid: {
        x: 35,
        y: 25,
        x2: 8,
        y2: 25,
    },
    xAxis: [{
        type: 'category',
        boundaryGap: false,
        axisLine: {
            lineStyle: {
                color: '#57617B'
            }
        },
        axisLabel: {
            color:'#fff'
        },
        data: [moment().subtract(4, 'months').format('YYYY/MM'), moment().subtract(3, 'months').format('YYYY/MM'), moment().subtract(2, 'months').format('YYYY/MM'), moment().subtract(1, 'months').format('YYYY/MM'), moment().format('YYYY/MM')]
    }],
    yAxis: [{
        type: 'value',
        axisTick: {
            show: false
        },
        axisLine: {
            lineStyle: {
                color: '#57617B'
            }
        },
        axisLabel: {
            margin: 10,
            color:'#fff',
            fontSize: 14
            
        },
        splitLine: {
            lineStyle: {
                color: '#57617B'
            }
        }
    }],
    series: [{
        name: '小型设备',
        type: 'line',
        smooth: true,
        lineStyle: {
            width: 2
        },
        areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(137, 189, 27, 0.3)'
            }, {
                offset: 0.8,
                color: 'rgba(137, 189, 27, 0)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
        },
        itemStyle: {
            color: 'rgb(137,189,27)'
        },
        data: [30,35,42,49,52]
    }, {
        name: '中型设备',
        type: 'line',
        smooth: true,
        lineStyle: {
            width: 2
        },
        areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0, 136, 212, 0.3)'
            }, {
                offset: 0.8,
                color: 'rgba(0, 136, 212, 0)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
        },
        itemStyle: {
            color: 'rgb(0,136,212)'
        },
        data: [20,27,30,38,47]
    }, {
        name: '大型设备',
        type: 'line',
        smooth: true,
        lineStyle: {
            width: 2
        },
        areaStyle: {
           
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(219, 50, 51, 0.3)'
            }, {
                offset: 0.8,
                color: 'rgba(219, 50, 51, 0)'
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
        },
        itemStyle: {
            color: 'rgb(219,50,51)'
        },
        data: [10,20,26,35,42]
    }, ]
};


//本月发生事件1
var color = ['#e9df3d', '#f79c19', '#21fcd6', '#08c8ff', '#df4131'];
var data = [{
        "name": "设备故障",
        "value": 5
    },
    {
        "name": "系统故障",
        "value": 12
    },
    {
        "name": "系统误报",
        "value": 1
    },
    {
        "name": "设备件保养",
        "value": 50
    },
    {
        "name": "零件更换",
        "value": 14
    }
];

var max = data[0].value;
data.forEach(function(d) {
    max = d.value > max ? d.value : max;
});

var renderData = [{
    value: [],
    name: "告警类型TOP5",
    symbol: 'none',
    lineStyle: {
        color: '#ecc03e',
        width: 2
    },
    areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0,
            [{
                offset: 0,
                color: 'rgba(203, 158, 24, 0.8)'
            }, {
                offset: 1,
                color: 'rgba(190, 96, 20, 0.8)'
            }],
            false)
    }
}];


data.forEach(function(d, i) {
    var value = ['', '', '', '', ''];
    value[i] = max,
    renderData[0].value[i] = d.value;
    renderData.push({
        value: value,
        symbol: 'circle',
        symbolSize: 12,
        lineStyle: {
            color: 'transparent'
        },
        itemStyle: {
            color: color[i],
        }
    })
})
var indicator = [];

data.forEach(function(d) {
    indicator.push({
        name: d.name,
        max: max,
        color: '#fff'
    })
})


export let option3 = {
    tooltip: {
        show: true,
        trigger: "item"
    },
    radar: {
        center: ["50%", "50%"],//偏移位置
        radius: "80%",
        startAngle: 40, // 起始角度
        splitNumber: 4,
        shape: "circle",
        splitArea: {
            areaStyle: {
                color: 'transparent'
            }
        },
        axisLabel: {
            show: false,
            fontSize: 20,
            color: "#000",
            fontStyle: "normal",
            fontWeight: "normal"
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        },
        indicator: indicator
    },
    series: [{
        type: "radar",
        data: renderData
    }]
}
//////////////////////本月发生事件1 end
//本月发生事件2
var color = ['#e9df3d', '#f79c19', '#21fcd6', '#08c8ff', '#df4131'];
var data = [{
        "name": "设备故障",
        "value": 1
    },
    {
        "name": "系统故障",
        "value": 10
    },
    {
        "name": "系统误报",
        "value": 15
    },
    {
        "name": "设备件保养",
        "value": 20
    },
    {
        "name": "零件更换",
        "value": 10
    }
];

var max = data[0].value;
data.forEach(function(d) {
    max = d.value > max ? d.value : max;
});

var renderData = [{
    value: [],
    name: "告警类型TOP5",
    symbol: 'none',
    lineStyle: {
        color: '#ecc03e',
        width: 2
    },
    areaStyle: {
        
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0,
            [{
                offset: 0,
                color: 'rgba(203, 158, 24, 0.8)'
            }, {
                offset: 1,
                color: 'rgba(190, 96, 20, 0.8)'
            }],
            false)
       
    }
}];


data.forEach(function(d, i) {
    var value = ['', '', '', '', ''];
    value[i] = max,
    renderData[0].value[i] = d.value;
    renderData.push({
        value: value,
        symbol: 'circle',
        symbolSize: 12,
        lineStyle: {
           color: 'transparent'
        },
        itemStyle: {
            color: color[i]
        }
    })
})
var indicator = [];

data.forEach(function(d) {
    indicator.push({
        name: d.name,
        max: max,
        color: '#fff'
    })
})


export let option31 = {
    tooltip: {
        show: true,
        trigger: "item"
    },
    radar: {
        center: ["50%", "50%"],//偏移位置
        radius: "80%",
        startAngle: 40, // 起始角度
        splitNumber: 4,
        shape: "circle",
        splitArea: {
            areaStyle: {
                color: 'transparent'
            }
        },
        axisLabel: {
            show: false,
            fontSize: 20,
            color: "#000",
            fontStyle: "normal",
            fontWeight: "normal"
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        },
        indicator: indicator
    },
    series: [{
        type: "radar",
        data: renderData
    }]
}
//////////////////////本月发生事件2 end



//收费站收费排行1
var spirit = '../images.ksh45.png';

var maxData = 200;

export let option4 = {
   title: {
      text: " ",
      left: "center",
      y: "10",
      textStyle: {
        color: "#fff"
      }
    },
    
    grid: {
      left: 80,
      right:30,
      top: 0,
      bottom: 10
    },
    tooltip: {
      trigger: "item",
      textStyle: {
        fontSize: 12
      },
      formatter: "{b0}:{c0}"
    },
    xAxis: {
      max: 100,
      splitLine: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    yAxis: [
      {
        type: "category",
        inverse: false,
        data: [
          "李家窑煤业",
          "草垛沟煤业",
          "高山煤业",
          "和尚嘴煤业",
          "新旺煤业",
        ],
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
            margin: -4,
            color: "#fff",
            fontSize: 16.25
          
        }
      },
    
    ],
    series: [
      {
        type: "pictorialBar",
        symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAADYElEQVR4nO2dz0sUYRjHP7tIdAmxQ1LdlhCKMohAIsgiyEuHjkUEFQTlpejS/xCCBB06RBGBBKIG4cGyH0qHBKE9eKyFqBQPRQeNCt06vGNY7bq7szPfeZLnAwuzM+/zgw/DDvMu70wOIVveLscJOwycA44A24CfwAfgKXAbeFVvovlC/o/vuVwuTj+x0FWiYdGbgXvA8RrjHgAXgIVaCbMU3SKr1BhtwEtgZx1jTwI7gG7ga5pNNUO+9pBMuEN9klfYD9xMqZdEsCj6AHAiRtxZYFeyrSSHRdGnYsblCD8jJrEoek8TsbsT6yJhLIrelFFsqlgUPZtRbKpYFP2kidjxxLpIGIuiB4AvMeLmgJGEe0kMi6I/AVdjxPVSx91hVlgUDXAXuEaY16jFMnAJeJhqR01iVTTAdeAYUFxjzBRwCLgl6agJrM51rDAO7AP2EmbxthPO8vfAc2Ams84axLpoCGKLrH1mm8eC6KPAGaAL2Fpj7AZgY7T9DfhRY/wc4eflPmH+OjOynI8uEGbpukXlJ4Dz84V8aWWHcj46q4thFzCNTjJRren2UrlLWPM3WYjuAMYIk/tq2oCx9lK5Q11YLboFGARaxXVX0woMtpfK0uuTWvRFoFNcsxKdhF5kqEX3iuuthbQXtehG/gdMG2kvlm/B1xUuWoSLFmFF9CRwg2TnM4pRzskEc8bGiugR4ArhNjkpJqKcJv51sSJ63eOiRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEWvTHKvs/p1izWu5qvaSCWvTlCvtmgeEUaw5TeUVtpV5SQy16COgBRoHXhMWb3aS7PnAhqjEQ1RwFeuYL+aEUa/5DFmtYHkefOEwQVmcBvKD+FQNvgNN/P+pHiV8MRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEixbhokVYEx3nudGKXE1jTfS6xUWLcNEiXLQIFy3CRYtw0SJctAgXLcJFi3DRIv430eUq2+axJvp7jePPqmzHySXFmuhHwFKVYzNA/6rv/VR/s9BSlMsM1kTPEN4DPkU4I8vAO6APOAgsrhq7GO3ri8aUo5ipKIep1zv9AtipgOACGIrLAAAAAElFTkSuQmCC",
        symbolRepeat: "fixed",
        symbolMargin: "5%",
        symbolClip: true,
        symbolSize: 21.5,
        symbolPosition: "start",
        symbolOffset: [
          20,
          0
        ],
        symbolBoundingData: 300,
        data: [
          13,
          42,
          67,
          81,
          86,
          
        ],
        z: 10
      },
      {
        type: "pictorialBar",
        itemStyle: {
            opacity: 0.3
        },
        label: {
           show: false
        },
        animationDuration: 0,
        symbolRepeat: "fixed",
        symbolMargin: "5%",
        symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAADYElEQVR4nO2dz0sUYRjHP7tIdAmxQ1LdlhCKMohAIsgiyEuHjkUEFQTlpejS/xCCBB06RBGBBKIG4cGyH0qHBKE9eKyFqBQPRQeNCt06vGNY7bq7szPfeZLnAwuzM+/zgw/DDvMu70wOIVveLscJOwycA44A24CfwAfgKXAbeFVvovlC/o/vuVwuTj+x0FWiYdGbgXvA8RrjHgAXgIVaCbMU3SKr1BhtwEtgZx1jTwI7gG7ga5pNNUO+9pBMuEN9klfYD9xMqZdEsCj6AHAiRtxZYFeyrSSHRdGnYsblCD8jJrEoek8TsbsT6yJhLIrelFFsqlgUPZtRbKpYFP2kidjxxLpIGIuiB4AvMeLmgJGEe0kMi6I/AVdjxPVSx91hVlgUDXAXuEaY16jFMnAJeJhqR01iVTTAdeAYUFxjzBRwCLgl6agJrM51rDAO7AP2EmbxthPO8vfAc2Ams84axLpoCGKLrH1mm8eC6KPAGaAL2Fpj7AZgY7T9DfhRY/wc4eflPmH+OjOynI8uEGbpukXlJ4Dz84V8aWWHcj46q4thFzCNTjJRren2UrlLWPM3WYjuAMYIk/tq2oCx9lK5Q11YLboFGARaxXVX0woMtpfK0uuTWvRFoFNcsxKdhF5kqEX3iuuthbQXtehG/gdMG2kvlm/B1xUuWoSLFmFF9CRwg2TnM4pRzskEc8bGiugR4ArhNjkpJqKcJv51sSJ63eOiRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEWvTHKvs/p1izWu5qvaSCWvTlCvtmgeEUaw5TeUVtpV5SQy16COgBRoHXhMWb3aS7PnAhqjEQ1RwFeuYL+aEUa/5DFmtYHkefOEwQVmcBvKD+FQNvgNN/P+pHiV8MRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEixbhokVYEx3nudGKXE1jTfS6xUWLcNEiXLQIFy3CRYtw0SJctAgXLcJFi3DRIv430eUq2+axJvp7jePPqmzHySXFmuhHwFKVYzNA/6rv/VR/s9BSlMsM1kTPEN4DPkU4I8vAO6APOAgsrhq7GO3ri8aUo5ipKIep1zv9AtipgOACGIrLAAAAAElFTkSuQmCC",
        symbolSize: 21.5,
        symbolBoundingData: 300,
        symbolPosition: "start",
        symbolOffset: [
          20,
          0
        ],
        data: [
          13,
          42,
          67,
          81,
          86,
          
        ],
        z: 5
      }
    ]
};


// Make dynamic data.
function random() {
    return +(Math.random() * (maxData - 10)).toFixed(1);
}
// setInterval(function () {
//     var dynamicData = [random(), random(), random(), random(),random(), random(), random(), random(),random(),random()];
//     myChart.setOption({
//         series: [{
//             data: dynamicData.slice()
//         }, {
//             data: dynamicData.slice()
//         }]
//     })
// }, 3000)
//////////////////////收费站收费排行2 end

//收费站收费排行2
var spirit = '../images.ksh45.png';

var maxData = 200;

export let option41 = {
   title: {
      text: " ",
      left: "center",
      y: "10",
      textStyle: {
        color: "#fff"
      }
    },
    
    grid: {
      left: 80,
      right:30,
      top: 0,
      bottom: 10
    },
    tooltip: {
      trigger: "item",
      textStyle: {
        fontSize: 12
      },
      formatter: "{b0}:{c0}"
    },
    xAxis: {
      max: 100,
      splitLine: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    yAxis: [
      {
        type: "category",
        inverse: false,
        data: [
          "新旺煤业",
          "草垛沟煤业",
          "高山煤业",
          "李家窑煤业",
          "和尚嘴煤业",
        ],
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
            margin: -4,
            color: "#fff",
            fontSize: 16.25
          
        }
      },
    
    ],
    series: [
      {
        type: "pictorialBar",
        symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAADYElEQVR4nO2dz0sUYRjHP7tIdAmxQ1LdlhCKMohAIsgiyEuHjkUEFQTlpejS/xCCBB06RBGBBKIG4cGyH0qHBKE9eKyFqBQPRQeNCt06vGNY7bq7szPfeZLnAwuzM+/zgw/DDvMu70wOIVveLscJOwycA44A24CfwAfgKXAbeFVvovlC/o/vuVwuTj+x0FWiYdGbgXvA8RrjHgAXgIVaCbMU3SKr1BhtwEtgZx1jTwI7gG7ga5pNNUO+9pBMuEN9klfYD9xMqZdEsCj6AHAiRtxZYFeyrSSHRdGnYsblCD8jJrEoek8TsbsT6yJhLIrelFFsqlgUPZtRbKpYFP2kidjxxLpIGIuiB4AvMeLmgJGEe0kMi6I/AVdjxPVSx91hVlgUDXAXuEaY16jFMnAJeJhqR01iVTTAdeAYUFxjzBRwCLgl6agJrM51rDAO7AP2EmbxthPO8vfAc2Ams84axLpoCGKLrH1mm8eC6KPAGaAL2Fpj7AZgY7T9DfhRY/wc4eflPmH+OjOynI8uEGbpukXlJ4Dz84V8aWWHcj46q4thFzCNTjJRren2UrlLWPM3WYjuAMYIk/tq2oCx9lK5Q11YLboFGARaxXVX0woMtpfK0uuTWvRFoFNcsxKdhF5kqEX3iuuthbQXtehG/gdMG2kvlm/B1xUuWoSLFmFF9CRwg2TnM4pRzskEc8bGiugR4ArhNjkpJqKcJv51sSJ63eOiRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEWvTHKvs/p1izWu5qvaSCWvTlCvtmgeEUaw5TeUVtpV5SQy16COgBRoHXhMWb3aS7PnAhqjEQ1RwFeuYL+aEUa/5DFmtYHkefOEwQVmcBvKD+FQNvgNN/P+pHiV8MRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEixbhokVYEx3nudGKXE1jTfS6xUWLcNEiXLQIFy3CRYtw0SJctAgXLcJFi3DRIv430eUq2+axJvp7jePPqmzHySXFmuhHwFKVYzNA/6rv/VR/s9BSlMsM1kTPEN4DPkU4I8vAO6APOAgsrhq7GO3ri8aUo5ipKIep1zv9AtipgOACGIrLAAAAAElFTkSuQmCC",
        symbolRepeat: "fixed",
        symbolMargin: "5%",
        symbolClip: true,
        symbolSize: 21.5,
        symbolPosition: "start",
        symbolOffset: [
          20,
          0
        ],
        symbolBoundingData: 300,
        data: [
          51,
          32,
          82,
          42,
          81,
          
        ],
        z: 10
      },
      {
        type: "pictorialBar",
        itemStyle: {
           opacity: 0.3
          
        },
        label: {
            show: false
        },
        animationDuration: 0,
        symbolRepeat: "fixed",
        symbolMargin: "5%",
        symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAADYElEQVR4nO2dz0sUYRjHP7tIdAmxQ1LdlhCKMohAIsgiyEuHjkUEFQTlpejS/xCCBB06RBGBBKIG4cGyH0qHBKE9eKyFqBQPRQeNCt06vGNY7bq7szPfeZLnAwuzM+/zgw/DDvMu70wOIVveLscJOwycA44A24CfwAfgKXAbeFVvovlC/o/vuVwuTj+x0FWiYdGbgXvA8RrjHgAXgIVaCbMU3SKr1BhtwEtgZx1jTwI7gG7ga5pNNUO+9pBMuEN9klfYD9xMqZdEsCj6AHAiRtxZYFeyrSSHRdGnYsblCD8jJrEoek8TsbsT6yJhLIrelFFsqlgUPZtRbKpYFP2kidjxxLpIGIuiB4AvMeLmgJGEe0kMi6I/AVdjxPVSx91hVlgUDXAXuEaY16jFMnAJeJhqR01iVTTAdeAYUFxjzBRwCLgl6agJrM51rDAO7AP2EmbxthPO8vfAc2Ams84axLpoCGKLrH1mm8eC6KPAGaAL2Fpj7AZgY7T9DfhRY/wc4eflPmH+OjOynI8uEGbpukXlJ4Dz84V8aWWHcj46q4thFzCNTjJRren2UrlLWPM3WYjuAMYIk/tq2oCx9lK5Q11YLboFGARaxXVX0woMtpfK0uuTWvRFoFNcsxKdhF5kqEX3iuuthbQXtehG/gdMG2kvlm/B1xUuWoSLFmFF9CRwg2TnM4pRzskEc8bGiugR4ArhNjkpJqKcJv51sSJ63eOiRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEWvTHKvs/p1izWu5qvaSCWvTlCvtmgeEUaw5TeUVtpV5SQy16COgBRoHXhMWb3aS7PnAhqjEQ1RwFeuYL+aEUa/5DFmtYHkefOEwQVmcBvKD+FQNvgNN/P+pHiV8MRbhoES5ahIsW4aJFuGgRLlqEixbhokW4aBEuWoSLFuGiRbhoES5ahIsW4aJFuGgRLlqEixbhokVYEx3nudGKXE1jTfS6xUWLcNEiXLQIFy3CRYtw0SJctAgXLcJFi3DRIv430eUq2+axJvp7jePPqmzHySXFmuhHwFKVYzNA/6rv/VR/s9BSlMsM1kTPEN4DPkU4I8vAO6APOAgsrhq7GO3ri8aUo5ipKIep1zv9AtipgOACGIrLAAAAAElFTkSuQmCC",
        symbolSize: 21.5,
        symbolBoundingData: 300,
        symbolPosition: "start",
        symbolOffset: [
          20,
          0
        ],
        data: [
          51,
          32,
          82,
          42,
          81,
          
        ],
        z: 5
      }
    ]
};


// Make dynamic data.
function randoma() {
    return +(Math.random() * (maxData - 10)).toFixed(1);
}
// setInterval(function () {
//     var dynamicData = [randoma(), randoma(), randoma(), randoma(),randoma(), randoma(), randoma(), randoma(),randoma(),randoma()];
//     myChart.setOption({
//         series: [{
//             data: dynamicData.slice()
//         }, {
//             data: dynamicData.slice()
//         }]
//     })
// }, 3000)
//////////////////////收费站收费排行2 end

//今日实时收费

export let option5 = (num)=>{
    let value = num || 80;
    return {
        title: {
            //text: `${value}万辆`,
            text: `车辆总数`,
            subtext: '',
            left: 'center',
            top: 'center',//top待调整
            textStyle: {
                color: '#fff',
                fontSize: 16,
                fontFamily: 'PingFangSC-Regular'
            },
            subtextStyle: {
                color: '#ff',
                fontSize: 14,
                fontFamily: 'PingFangSC-Regular',
                top: 'center'
            },
            itemGap: -1//主副标题间距
        },
    
        series: [{
            name: 'pie1',
            type: 'pie',
            clockwise: true,
            radius: ['65%', '70%'],
            label: {
                show: false
            },
            labelLine: {
                show: false
            },
            emphasis: {
               scale:false 
            },
            data: [{
                value: value,
                name: 'completed',
                itemStyle: {
                    
                    borderWidth: 8,
                    borderColor: { 
                        colorStops: [{
                            offset: 0,
                            color: '#1d54f7' || '#00cefc' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#68eaf9' || '#367bec' // 100% 处的颜色
                        }]
                    },
                    color: { // 完成的圆环的颜色
                        colorStops: [{
                            offset: 0,
                            color: '#1d54f7' || '#00cefc' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#68eaf9' || '#367bec' // 100% 处的颜色
                        }]
                    },
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    }
                    
                }
            }, {
                name: 'gap',
                value: 100 - value,
                itemStyle: {
                   
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    color: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0
                    
                }
            }]
        }]
    }
}

var shadowColor = '#374b86';

export let option6 = (num)=>{
    let value = num || 85;
    return {
    
        title: {
            //text: `${value}万辆`,
            text: `今日上线`,
            subtext: '',
            left: 'center',
            top: 'center',//top待调整
            textStyle: {
                color: '#fff',
                fontSize: 16,
                fontFamily: 'PingFangSC-Regular'
            },
            subtextStyle: {
                color: '#ff',
                fontSize: 14,
                fontFamily: 'PingFangSC-Regular',
                top: 'center'
            },
            itemGap: -1//主副标题间距
        },
    
        series: [{
            name: 'pie1',
            type: 'pie',
            clockwise: true,
            radius: ['65%', '70%'],
            label: {
                show: false
            },
            labelLine: {
                show: false
            },
            emphasis: {
                scale:false 
            },
            data: [{
                value: value,
                name: 'completed',
                itemStyle: {
                   
                    borderWidth: 8,
                    borderColor: { 
                        colorStops: [{
                            offset: 0,
                            color: '#02df94' || '#25d6bc' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#28d3d0' || '#14dbaa' // 100% 处的颜色
                        }]
                    },
                    color: { // 完成的圆环的颜色
                        colorStops: [{
                            offset: 0,
                            color: '#02df94' || '#25d6bc' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#28d3d0' || '#14dbaa' // 100% 处的颜色
                        }]
                    },
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    }
                    
                }
            }, {
                name: 'gap',
                value: 100 - value,
                itemStyle: {
                   
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    color: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0
                    
                }
            }]
        }]
    }
}

var shadowColor = '#374b86';

export let option7 = (num)=>{
    let value = num || 46;
    return {
        title: {
            text: `今日报警`,
            subtext: '',
            left: 'center',
            top: 'center',
            textStyle: {
                color: '#fff',
                fontSize: 16,
                fontFamily: 'PingFangSC-Regular'
            },
            subtextStyle: {
                color: '#ff',
                fontSize: 14,
                fontFamily: 'PingFangSC-Regular',
                top: 'center'
            },
            itemGap: -1//主副标题间距
        },
    
        series: [{
            name: 'pie1',
            type: 'pie',
            clockwise: true,
            radius: ['65%', '70%'],
            label: {
                show: false
            },
            labelLine: {
                show: false
            },
            emphasis: {
                scale:false 
            },
            data: [{
                value: value,
                name: 'completed',
                itemStyle: {
                   
                    borderWidth: 8,
                    borderColor: { 
                    colorStops: [{
                            offset: 0,
                            color: '#eb3600' || '#cc9a00' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#d0a00e' || '#d0570e' // 100% 处的颜色
                        }]
                    },
                    color: { // 完成的圆环的颜色
                        colorStops: [{
                            offset: 0,
                            color: '#eb3600' || '#cc9a00' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#d0a00e' || '#d0570e' // 100% 处的颜色
                        }]
                    },
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    }
                  
                }
            }, {
                name: 'gap',
                value: 100 - value,
                itemStyle: {
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    color: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0
                    
                }
            }]
        }]
    }
}
//////////////////////今日实时收费 end

















