import {
    createApp
} from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element';
import * as Icons from '@element-plus/icons-vue' // 引入所有图标，并命名为 Icons
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import moment from 'moment';
const app = createApp(App);
app.config.globalProperties.$echarts = echarts //引入组件
app.config.globalProperties.$moment = moment //引入日期插件
for (let i in Icons) {
    app.component(i, Icons[i])
}

app.use(store);
app.use(router);
installElementPlus(app);
app.mount('#app');