import * as echarts from 'echarts'
import '@/units/china'
import shanxi from './shanxi';
var allData = {
	"citys": [{
		"name": "延寿",
		"value": [128.331644, 45.451897, 2],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "临江",
		"value": [126.918087, 41.811979, 2],
		"symbolSize": 2,
		"itemStyle": {
		
				"color": "#F58158"
		
		}
	}, {
		"name": "嘉兴",
		"value": [120.755486, 30.746129, 4],
		"symbolSize": 2,
		"itemStyle": {
		
				"color": "#F58158"
		
		}
	}, {
		"name": "四平",
		"value": [124.350398, 43.16642, 1],
		"symbolSize": 2,
		"itemStyle": {
		
				"color": "#F58158"
	
		}
	}, {
		"name": "营口",
		"value": [122.235418, 40.667012, 1],
		"symbolSize": 2,
		"itemStyle": {
			
				"color": "#F58158"
			
		}
	}, {
		"name": "密云",
		"value": [116.801346, 40.35874, 1],
		"symbolSize": 2,
		"itemStyle": {
			
				"color": "#F58158"
		
		}
	}, {
		"name": "威海",
		"value": [122.12042, 37.513068, 32],
		"symbolSize": 3,
		"itemStyle": {
		
				"color": "#F58158"
		
		}
	}, {
		"name": "杭州",
		"value": [120.15507, 30.274085, 10],
		"symbolSize": 2,
		"itemStyle": {
			
				"color": "#F58158"
		
		}
	}, {
		"name": "东港",
		"value": [124.152705, 39.863008, 1],
		"symbolSize": 2,
		"itemStyle": {
		
				"color": "#F58158"
		
		}
	}, {
		"name": "廊坊",
		"value": [116.683752, 39.538047, 4],
		"symbolSize": 2,
		"itemStyle": {
			
				"color": "#F58158"
			
		}
	}, {
		"name": "鞍山",
		"value": [122.994329, 41.108647, 1],
		"symbolSize": 2,
		"itemStyle": {
		
				"color": "#F58158"
		
		}
	}, {
		"name": "海陵",
		"value": [119.919425, 32.491016, 1],
		"symbolSize": 2,
		"itemStyle": {
		
				"color": "#F58158"
		
		}
	}, {
		"name": "黑龙江",
		"value": [126.661669, 45.742347, -198],
		"symbolSize": 8,
		"itemStyle": {
				"color": "#58B3CC"
			
		}
	}, {
		"name": "西藏",
		"value": [91.117212, 29.646923, -1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#58B3CC"
			
		}
	}, {
		"name": "河南",
		"value": [113.274379, 34.445122, 0],
		"symbolSize": 10,
		"itemStyle": {
			"color": "#58B3CC"
		},
		children:[{
			name:'子元素1'
		},{
			name:'子元素2'
		},{
			name:'子元素3'
		}]
	}, {
		"name": "湖南",
		"value": [112.98381, 28.112444, -1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#58B3CC"
			
		}
	}, {
		"name": "佛山",
		"value": [113.121416, 23.021548, 2],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "珲春",
		"value": [130.366036, 42.862821, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "扬州",
		"value": [119.412966, 32.39421, 5],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "日照",
		"value": [119.526888, 35.416377, 2],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "唐山",
		"value": [118.180194, 39.630867, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "同江",
		"value": [132.510919, 47.642707, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "荣成",
		"value": [122.486658, 37.16516, 4],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "虎林",
		"value": [132.93721, 45.762686, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "武汉",
		"value": [114.305393, 30.593099, 2],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "合肥",
		"value": [117.227239, 31.820587, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "荆州",
		"value": [112.239741, 30.335165, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "丰台",
		"value": [116.287149, 39.858427, 3],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "山东",
		"value": [117.020359, 36.66853, -6],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#58B3CC"
			
		}
	}, {
		"name": "舟山",
		"value": [122.207216, 29.985295, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "连云港",
		"value": [119.221611, 34.596653, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "西安",
		"value": [108.940175, 34.341568, 3],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "济南",
		"value": [117.12, 36.651216, 4],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "绵阳",
		"value": [104.679114, 31.46745, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "辽宁",
		"value": [123.42944, 41.835441, -58],
		"symbolSize": 3,
		"itemStyle": {
				"color": "#58B3CC"
			
		}
	}, {
		"name": "山西",
		"value": [112.562398, 37.873532, -3],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#58B3CC"
			
		}
	}, {
		"name": "呼和浩特",
		"value": [111.749181, 40.842585, 2],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "河西",
		"value": [117.223372, 39.109563, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "兴和",
		"value": [113.834173, 40.872301, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "重庆",
		"value": [106.551557, 29.56301, 2],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "胶州",
		"value": [120.033382, 36.26468, 5],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "宁波",
		"value": [121.550357, 29.874557, 10],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "滨海",
		"value": [119.820831, 33.990334, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "太原",
		"value": [112.548879, 37.87059, 2],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "鸡西",
		"value": [130.969333, 45.295075, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "兰山",
		"value": [118.347707, 35.051729, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "阳泉",
		"value": [113.580519, 37.856972, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "勃利",
		"value": [130.592171, 45.755063, 1],
		"symbolSize": 2,
		"itemStyle": {
				"color": "#F58158"
			
		}
	}, {
		"name": "长春",
		"value": [125.323544, 43.817072, 8],
		"symbolSize": 2,
		"itemStyle": {
			"color": "#F58158"
		}
	}],
	"moveLines": [{
		"fromName": "大同",
		"toName": "珠海",
		"coords": [
			[113.295259,40.09031],
			[113.576726, 22.270715]
		]
	}, {
		"fromName": "大同",
		"toName": "舒兰",
		"coords": [
			[113.295259,40.09031],
			[126.965607, 44.406106]
		]
	}, {
		"fromName": "大同",
		"toName": "大连",
		"coords": [
			[113.295259,40.09031],
			[121.614682, 38.914003]
		]
	}, {
		"fromName": "大同",
		"toName": "集安",
		"coords": [
			[113.295259,40.09031],
			[126.194031, 41.125307]
		]
	}, {
		"fromName": "大同",
		"toName": "抚顺",
		"coords": [
			[113.295259,40.09031],
			[123.957208, 41.880872]
		]
	}, {
		"fromName": "大同",
		"toName": "南京",
		"coords": [
			[113.295259,40.09031],
			[118.796877, 32.060255]
		]
	}, {
		"fromName": "大同",
		"toName": "沈阳",
		"coords": [
			[113.295259,40.09031],
			[123.431475, 41.805698]
		]
	}, {
		"fromName": "大同",
		"toName": "环翠",
		"coords": [
			[113.295259,40.09031],
			[122.123444, 37.501991]
		]
	}, {
		"fromName": "大同",
		"toName": "大连",
		"coords": [
			[113.295259,40.09031],
			[121.614682, 38.914003]
		]
	}, {
		"fromName": "大同",
		"toName": "兴和",
		"coords": [
			[113.295259,40.09031],
			[113.834173, 40.872301]
		]
	}, {
		"fromName": "大同",
		"toName": "勃利",
		"coords": [
			[113.295259,40.09031],
			[130.592171, 45.755063]
		]
	}, {
		"fromName": "大同",
		"toName": "大连",
		"coords": [
			[113.295259,40.09031],
			[121.614682, 38.914003]
		]
	}, {
		"fromName": "大同",
		"toName": "沈阳",
		"coords": [
			[113.295259,40.09031],
			[123.431475, 41.805698]
		]
	}, {
		"fromName": "大同",
		"toName": "闵行",
		"coords": [
			[113.295259,40.09031],
			[121.381709, 31.112813]
		]
	}, {
		"fromName": "大同",
		"toName": "朝阳",
		"coords": [
			[113.295259,40.09031],
			[116.443108, 39.92147]
		]
	}, {
		"fromName": "大同",
		"toName": "黄岛",
		"coords": [
			[113.295259,40.09031],
			[120.04619, 35.872664]
		]
	}, {
		"fromName": "大同",
		"toName": "上海",
		"coords": [
			[113.295259,40.09031],
			[121.473701, 31.230416]
		]
	}, {
		"fromName": "大同",
		"toName": "南京",
		"coords": [
			[113.295259,40.09031],
			[118.796877, 32.060255]
		]
	}, {
		"fromName": "大同",
		"toName": "杭州",
		"coords": [
			[113.295259,40.09031],
			[120.15507, 30.274085]
		]
	}, {
		"fromName": "大同",
		"toName": "海城",
		"coords": [
			[113.295259,40.09031],
			[122.685217, 40.882377]
		]
	}, {
		"fromName": "大同",
		"toName": "西城",
		"coords": [
			[113.295259,40.09031],
			[116.365868, 39.912289]
		]
	}, {
		"fromName": "大同",
		"toName": "上海",
		"coords": [
			[113.295259,40.09031],
			[121.473701, 31.230416]
		]
	}, {
		"fromName": "大同",
		"toName": "西城",
		"coords": [
			[113.295259,40.09031],
			[116.365868, 39.912289]
		]
	}, {
		"fromName": "大同",
		"toName": "丹东",
		"coords": [
			[113.295259,40.09031],
			[124.354707, 40.0005]
		]
	}, {
		"fromName": "大同",
		"toName": "宁波",
		"coords": [
			[113.295259,40.09031],
			[121.550357, 29.874557]
		]
	}, {
		"fromName": "大同",
		"toName": "海淀",
		"coords": [
			[113.295259,40.09031],
			[116.298056, 39.959912]
		]
	}, {
		"fromName": "大同",
		"toName": "青岛",
		"coords": [
			[113.295259,40.09031],
			[120.38264, 36.067082]
		]
	}, {
		"fromName": "大同",
		"toName": "苏州",
		"coords": [
			[113.295259,40.09031],
			[120.585316, 31.298886]
		]
	}, {
		"fromName": "大同",
		"toName": "抚顺",
		"coords": [
			[113.295259,40.09031],
			[123.957208, 41.880872]
		]
	}, {
		"fromName": "大同",
		"toName": "临安",
		"coords": [
			[113.295259,40.09031],
			[119.724733, 30.233873]
		]
	}, {
		"fromName": "大同",
		"toName": "烟台",
		"coords": [
			[113.295259,40.09031],
			[121.447935, 37.463822]
		]
	}, {
		"fromName": "大同",
		"toName": "海淀",
		"coords": [
			[113.295259,40.09031],
			[116.298056, 39.959912]
		]
	}, {
		"fromName": "大同",
		"toName": "南昌",
		"coords": [
			[113.295259,40.09031],
			[115.858198, 28.682892]
		]
	}, {
		"fromName": "大同",
		"toName": "沈阳",
		"coords": [
			[113.295259,40.09031],
			[123.431475, 41.805698]
		]
	}, {
		"fromName": "大同",
		"toName": "城阳",
		"coords": [
			[113.295259,40.09031],
			[120.39631, 36.307064]
		]
	}, {
		"fromName": "大同",
		"toName": "广州",
		"coords": [
			[113.295259,40.09031],
			[113.264435, 23.129163]
		]
	}, {
		"fromName": "大同",
		"toName": "沈阳",
		"coords": [
			[113.295259,40.09031],
			[123.431475, 41.805698]
		]
	}, {
		"fromName": "大同",
		"toName": "阳泉",
		"coords": [
			[113.295259,40.09031],
			[113.580519, 37.856972]
		]
	}, {
		"fromName": "大同",
		"toName": "桦川",
		"coords": [
			[113.295259,40.09031],
			[130.719081, 47.023001]
		]
	}, {
		"fromName": "大同",
		"toName": "海淀",
		"coords": [
			[113.295259,40.09031],
			[116.298056, 39.959912]
		]
	}, {
		"fromName": "大同",
		"toName": "河北",
		"coords": [
			[113.295259,40.09031],
			[114.475704, 38.584854]
		]
	}, {
		"fromName": "大同",
		"toName": "呼和浩特",
		"coords": [
			[113.295259,40.09031],
			[111.749181, 40.842585]
		]
	}, {
		"fromName": "大同",
		"toName": "茂名",
		"coords": [
			[113.295259,40.09031],
			[110.925456, 21.662999]
		]
	}, {
		"fromName": "大同",
		"toName": "东城",
		"coords": [
			[113.295259,40.09031],
			[116.416357, 39.928353]
		]
	}, {
		"fromName": "大同",
		"toName": "盘锦",
		"coords": [
			[113.295259,40.09031],
			[122.070714, 41.119997]
		]
	}, {
		"fromName": "大同",
		"toName": "哈尔滨",
		"coords": [
			[113.295259,40.09031],
			[126.534967, 45.803775]
		]
	}, {
		"fromName": "大同",
		"toName": "沈阳",
		"coords": [
			[113.295259,40.09031],
			[123.431475, 41.805698]
		]
	}, {
		"fromName": "大同",
		"toName": "丰台",
		"coords": [
			[113.295259,40.09031],
			[116.287149, 39.858427]
		]
	}, {
		"fromName": "大同",
		"toName": "盘锦",
		"coords": [
			[113.295259,40.09031],
			[122.070714, 41.119997]
		]
	}, {
		"fromName": "大同",
		"toName": "皇姑",
		"coords": [
			[113.295259,40.09031],
			[123.44197, 41.824796]
		]
	}, {
		"fromName": "大同",
		"toName": "虎林",
		"coords": [
			[113.295259,40.09031],
			[132.93721, 45.762686]
		]
	}, {
		"fromName": "大同",
		"toName": "宝山",
		"coords": [
			[113.295259,40.09031],
			[131.401589, 46.577167]
		]
	}, {
		"fromName": "大同",
		"toName": "吉林",
		"coords": [
			[113.295259,40.09031],
			[126.549572, 43.837883]
		]
	}, {
		"fromName": "大同",
		"toName": "青岛",
		"coords": [
			[113.295259,40.09031],
			[120.38264, 36.067082]
		]
	}, {
		"fromName": "大同",
		"toName": "烟台",
		"coords": [
			[113.295259,40.09031],
			[121.447935, 37.463822]
		]
	}, {
		"fromName": "大同",
		"toName": "临江",
		"coords": [
			[113.295259,40.09031],
			[126.918087, 41.811979]
		]
	}, {
		"fromName": "大同",
		"toName": "黄岛",
		"coords": [
			[113.295259,40.09031],
			[120.04619, 35.872664]
		]
	}, {
		"fromName": "大同",
		"toName": "石家庄",
		"coords": [
			[113.295259,40.09031],
			[114.51486, 38.042307]
		]
	}, {
		"fromName": "大同",
		"toName": "汤原",
		"coords": [
			[113.295259,40.09031],
			[129.905072, 46.730706]
		]
	}, {
		"fromName": "大同",
		"toName": "临江",
		"coords": [
			[113.295259,40.09031],
			[126.918087, 41.811979]
		]
	}, {
		"fromName": "大同",
		"toName": "济南",
		"coords": [
			[113.295259,40.09031],
			[117.12, 36.651216]
		]
	}, {
		"fromName": "大同",
		"toName": "太原",
		"coords": [
			[113.295259,40.09031],
			[112.548879, 37.87059]
		]
	}, {
		"fromName": "大同",
		"toName": "威海",
		"coords": [
			[113.295259,40.09031],
			[122.12042, 37.513068]
		]
	}, {
		"fromName": "太原",
		"toName": "深圳",
		"coords": [
			[112.549248,37.857014],
			[114.057868, 22.543099]
		]
	}, {
		"fromName": "太原",
		"toName": "荣成",
		"coords": [
			[112.549248,37.857014],
			[122.486658, 37.16516]
		]
	}, {
		"fromName": "太原",
		"toName": "郑州",
		"coords": [
			[112.549248,37.857014],
			[113.625368, 34.7466]
		]
	}, {
		"fromName": "太原",
		"toName": "朝阳",
		"coords": [
			[112.549248,37.857014],
			[116.443108, 39.92147]
		]
	}, {
		"fromName": "太原",
		"toName": "昆山",
		"coords": [
			[112.549248,37.857014],
			[120.980737, 31.385598]
		]
	}, {
		"fromName": "太原",
		"toName": "双城",
		"coords": [
			[112.549248,37.857014],
			[126.312745, 45.383263]
		]
	}, {
		"fromName": "太原",
		"toName": "克拉玛依",
		"coords": [
			[112.549248,37.857014],
			[84.889207, 45.579889]
		]
	}, {
		"fromName": "太原",
		"toName": "上海",
		"coords": [
			[112.549248,37.857014],
			[121.473701, 31.230416]
		]
	}, {
		"fromName": "太原",
		"toName": "海阳",
		"coords": [
			[112.549248,37.857014],
			[121.158434, 36.776378]
		]
	}, {
		"fromName": "太原",
		"toName": "宣武门外东大街",
		"coords": [
			[126.549572, 43.837883],
			[116.378888, 39.899332]
		]
	}, {
		"fromName": "太原",
		"toName": "海淀",
		"coords": [
			[112.549248,37.857014],
			[116.298056, 39.959912]
		]
	}, {
		"fromName": "太原",
		"toName": "威海",
		"coords": [
			[112.549248,37.857014],
			[122.12042, 37.513068]
		]
	}, {
		"fromName": "太原",
		"toName": "晋中",
		"coords": [
			[112.549248,37.857014],
			[112.752695, 37.687024]
		]
	}, {
		"fromName": "太原",
		"toName": "广州",
		"coords": [
			[112.549248,37.857014],
			[113.264435, 23.129163]
		]
	}, {
		"fromName": "太原",
		"toName": "无锡",
		"coords": [
			[112.549248,37.857014],
			[120.31191, 31.49117]
		]
	}, {
		"fromName": "太原",
		"toName": "城阳",
		"coords": [
			[112.549248,37.857014],
			[120.39631, 36.307064]
		]
	}, {
		"fromName": "太原",
		"toName": "丰台",
		"coords": [
			[112.549248,37.857014],
			[116.287149, 39.858427]
		]
	}, {
		"fromName": "太原",
		"toName": "扬州",
		"coords": [
			[112.549248,37.857014],
			[119.412966, 32.39421]
		]
	}, {
		"fromName": "太原",
		"toName": "天津",
		"coords": [
			[112.549248,37.857014],
			[117.200983, 39.084158]
		]
	}, {
		"fromName": "太原",
		"toName": "扬州",
		"coords": [
			[112.549248,37.857014],
			[119.412966, 32.39421]
		]
	}, {
		"fromName": "太原",
		"toName": "嘉兴",
		"coords": [
			[112.549248,37.857014],
			[120.755486, 30.746129]
		]
	}, {
		"fromName": "太原",
		"toName": "延寿",
		"coords": [
			[112.549248,37.857014],
			[128.331644, 45.451897]
		]
	}, {
		"fromName": "太原",
		"toName": "义乌",
		"coords": [
			[112.549248,37.857014],
			[120.075058, 29.306841]
		]
	}, {
		"fromName": "太原",
		"toName": "张家港",
		"coords": [
			[112.549248,37.857014],
			[120.553284, 31.870367]
		]
	}, {
		"fromName": "太原",
		"toName": "贵阳",
		"coords": [
			[112.549248,37.857014],
			[106.630154, 26.647661]
		]
	}, {
		"fromName": "太原",
		"toName": "辽宁",
		"coords": [
			[112.549248,37.857014],
			[123.42944, 41.835441]
		]
	}, {
		"fromName": "太原",
		"toName": "营口",
		"coords": [
			[112.549248,37.857014],
			[122.235418, 40.667012]
		]
	}, {
		"fromName": "太原",
		"toName": "合肥",
		"coords": [
			[112.549248,37.857014],
			[117.227239, 31.820587]
		]
	}, {
		"fromName": "太原",
		"toName": "苏州",
		"coords": [
			[112.549248,37.857014],
			[120.585316, 31.298886]
		]
	}, {
		"fromName": "太原",
		"toName": "榆树",
		"coords": [
			[112.549248,37.857014],
			[126.533146, 44.840288]
		]
	}, {
		"fromName": "太原",
		"toName": "常熟",
		"coords": [
			[112.549248,37.857014],
			[120.752481, 31.654376]
		]
	}, {
		"fromName": "太原",
		"toName": "乳山",
		"coords": [
			[112.549248,37.857014],
			[121.539765, 36.919816]
		]
	}, {
		"fromName": "太原",
		"toName": "青岛",
		"coords": [
			[112.549248,37.857014],
			[120.38264, 36.067082]
		]
	}, {
		"fromName": "太原",
		"toName": "深圳",
		"coords": [
			[112.549248,37.857014],
			[114.057868, 22.543099]
		]
	}, {
		"fromName": "太原",
		"toName": "东城",
		"coords": [
			[112.549248,37.857014],
			[116.416357, 39.928353]
		]
	}, {
		"fromName": "太原",
		"toName": "上海",
		"coords": [
			[112.549248,37.857014],
			[121.473701, 31.230416]
		]
	}, {
		"fromName": "太原",
		"toName": "宁波",
		"coords": [
			[112.549248,37.857014],
			[121.550357, 29.874557]
		]
	}, {
		"fromName": "太原",
		"toName": "海门",
		"coords": [
			[112.549248,37.857014],
			[121.181615, 31.871173]
		]
	}, {
		"fromName": "太原",
		"toName": "沈阳",
		"coords": [
			[112.549248,37.857014],
			[123.431475, 41.805698]
		]
	}, {
		"fromName": "太原",
		"toName": "成都",
		"coords": [
			[112.549248,37.857014],
			[104.066541, 30.572269]
		]
	}, {
		"fromName": "太原",
		"toName": "南昌",
		"coords": [
			[112.549248,37.857014],
			[115.858198, 28.682892]
		]
	}, {
		"fromName": "太原",
		"toName": "常州",
		"coords": [
			[112.549248,37.857014],
			[119.973987, 31.810689]
		]
	}, {
		"fromName": "太原",
		"toName": "兰山",
		"coords": [
			[112.549248,37.857014],
			[118.347707, 35.051729]
		]
	}, {
		"fromName": "太原",
		"toName": "河南",
		"coords": [
			[112.549248,37.857014],
			[113.274379, 34.445122]
		]
	}, {
		"fromName": "太原",
		"toName": "福田",
		"coords": [
			[112.549248,37.857014],
			[114.055036, 22.52153]
		]
	}, {
		"fromName": "太原",
		"toName": "常州",
		"coords": [
			[112.549248,37.857014],
			[119.973987, 31.810689]
		]
	}, {
		"fromName": "太原",
		"toName": "双流",
		"coords": [
			[112.549248,37.857014],
			[103.923648, 30.574473]
		]
	}, {
		"fromName": "太原",
		"toName": "潍坊",
		"coords": [
			[112.549248,37.857014],
			[119.161756, 36.706774]
		]
	}, {
		"fromName": "太原",
		"toName": "延安",
		"coords": [
			[112.549248,37.857014],
			[109.489727, 36.585455]
		]
	}, {
		"fromName": "太原",
		"toName": "长春",
		"coords": [
			[112.549248,37.857014],
			[125.323544, 43.817072]
		]
	}, {
		"fromName": "太原",
		"toName": "南京",
		"coords": [
			[112.549248,37.857014],
			[118.796877, 32.060255]
		]
	}, {
		"fromName": "太原",
		"toName": "和平",
		"coords": [
			[112.549248,37.857014],
			[117.21451, 39.116949]
		]
	}, {
		"fromName": "太原",
		"toName": "哈尔滨",
		"coords": [
			[112.549248,37.857014],
			[126.534967, 45.803775]
		]
	}, {
		"fromName": "太原",
		"toName": "武汉",
		"coords": [
			[112.549248,37.857014],
			[114.305393, 30.593099]
		]
	}, {
		"fromName": "太原",
		"toName": "海陵",
		"coords": [
			[112.549248,37.857014],
			[119.919425, 32.491016]
		]
	}, {
		"fromName": "太原",
		"toName": "日照",
		"coords": [
			[112.549248,37.857014],
			[119.526888, 35.416377]
		]
	}, {
		"fromName": "太原",
		"toName": "台州",
		"coords": [
			[112.549248,37.857014],
			[121.420757, 28.656386]
		]
	}, {
		"fromName": "太原",
		"toName": "厦门",
		"coords": [
			[112.549248,37.857014],
			[118.089425, 24.479834]
		]
	}, {
		"fromName": "太原",
		"toName": "贵阳",
		"coords": [
			[112.549248,37.857014],
			[106.630154, 26.647661]
		]
	}, {
		"fromName": "太原",
		"toName": "鞍山",
		"coords": [
			[112.549248,37.857014],
			[122.994329, 41.108647]
		]
	}, {
		"fromName": "太原",
		"toName": "荣成",
		"coords": [
			[112.549248,37.857014],
			[122.486658, 37.16516]
		]
	}, {
		"fromName": "太原",
		"toName": "天津",
		"coords": [
			[112.549248,37.857014],
			[117.200983, 39.084158]
		]
	}, {
		"fromName": "太原",
		"toName": "河西",
		"coords": [
			[112.549248,37.857014],
			[117.223372, 39.109563]
		]
	}, {
		"fromName": "太原",
		"toName": "秦皇岛",
		"coords": [
			[112.549248,37.857014],
			[119.600493, 39.935385]
		]
	}, {
		"fromName": "太原",
		"toName": "荆州",
		"coords": [
			[112.549248,37.857014],
			[112.239741, 30.335165]
		]
	}, {
		"fromName": "太原",
		"toName": "东城",
		"coords": [
			[112.549248,37.857014],
			[116.416357, 39.928353]
		]
	}, {
		"fromName": "太原",
		"toName": "即墨",
		"coords": [
			[112.549248,37.857014],
			[120.447128, 36.389639]
		]
	}, {
		"fromName": "阳泉",
		"toName": "西城",
		"coords": [
			[113.583285,37.861188],
			[116.365868, 39.912289]
		]
	}, {
		"fromName": "阳泉",
		"toName": "大兴",
		"coords": [
			[113.583285,37.861188],
			[116.341395, 39.726929]
		]
	}, {
		"fromName": "阳泉",
		"toName": "哈尔滨",
		"coords": [
			[113.583285,37.861188],
			[126.534967, 45.803775]
		]
	}, {
		"fromName": "阳泉",
		"coords": [
			[113.583285,37.861188],
			[118.763232, 32.061707]
		]
	}, {
		"fromName": "阳泉",
		"toName": "和平",
		"coords": [
			[113.583285,37.861188],
			[117.21451, 39.116949]
		]
	}, {
		"fromName": "阳泉",
		"toName": "鸡东",
		"coords": [
			[113.583285,37.861188],
			[131.12408, 45.260412]
		]
	}, {
		"fromName": "阳泉",
		"toName": "辉南",
		"coords": [
			[113.583285,37.861188],
			[126.046912, 42.684993]
		]
	}, {
		"fromName": "阳泉",
		"toName": "深圳",
		"coords": [
			[113.583285,37.861188],
			[114.057868, 22.543099]
		]
	}, {
		"fromName": "阳泉",
		"toName": "泰顺",
		"coords": [
			[113.583285,37.861188],
			[119.717649, 27.556884]
		]
	}, {
		"fromName": "阳泉",
		"toName": "深圳",
		"coords": [
			[113.583285,37.861188],
			[114.057868, 22.543099]
		]
	}, {
		"fromName": "阳泉",
		"toName": "秦皇岛",
		"coords": [
			[113.583285,37.861188],
			[119.600493, 39.935385]
		]
	}, {
		"fromName": "阳泉",
		"toName": "徐汇",
		"coords": [
			[113.583285,37.861188],
			[121.436525, 31.188523]
		]
	}, {
		"fromName": "阳泉",
		"toName": "石景山",
		"coords": [
			[113.583285,37.861188],
			[116.222982, 39.906611]
		]
	}, {
		"fromName": "阳泉",
		"toName": "城阳",
		"coords": [
			[113.583285,37.861188],
			[120.39631, 36.307064]
		]
	}, {
		"fromName": "阳泉",
		"toName": "威海",
		"coords": [
			[113.583285,37.861188],
			[122.12042, 37.513068]
		]
	}, {
		"fromName": "阳泉",
		"toName": "惠州",
		"coords": [
			[113.583285,37.861188],
			[114.416196, 23.111847]
		]
	}, {
		"fromName": "阳泉",
		"toName": "龙口",
		"coords": [
			[113.583285,37.861188],
			[120.477813, 37.646108]
		]
	}, {
		"fromName": "阳泉",
		"toName": "四平",
		"coords": [
			[113.583285,37.861188],
			[124.350398, 43.16642]
		]
	}, {
		"fromName": "阳泉",
		"toName": "南充",
		"coords": [
			[113.583285,37.861188],
			[106.110698, 30.837793]
		]
	}, {
		"fromName": "阳泉",
		"toName": "东港",
		"coords": [
			[113.583285,37.861188],
			[124.152705, 39.863008]
		]
	}, {
		"fromName": "阳泉",
		"toName": "西安",
		"coords": [
			[113.583285,37.861188],
			[108.940175, 34.341568]
		]
	}, {
		"fromName": "阳泉",
		"toName": "滨海",
		"coords": [
			[113.583285,37.861188],
			[119.820831, 33.990334]
		]
	}, {
		"fromName": "阳泉",
		"toName": "青岛",
		"coords": [
			[113.583285,37.861188],
			[120.38264, 36.067082]
		]
	}, {
		"fromName": "阳泉",
		"toName": "昆山",
		"coords": [
			[113.583285,37.861188],
			[120.980737, 31.385598]
		]
	}, {
		"fromName": "阳泉",
		"toName": "长沙",
		"coords": [
			[113.583285,37.861188],
			[112.938814, 28.228209]
		]
	}, {
		"fromName": "阳泉",
		"toName": "哈尔滨",
		"coords": [
			[113.583285,37.861188],
			[126.534967, 45.803775]
		]
	}, {
		"fromName": "阳泉",
		"toName": "尚志",
		"coords": [
			[113.583285,37.861188],
			[128.009895, 45.209586]
		]
	}, {
		"fromName": "阳泉",
		"toName": "东城",
		"coords": [
			[113.583285,37.861188],
			[116.416357, 39.928353]
		]
	}, {
		"fromName": "阳泉",
		"toName": "珠海",
		"coords": [
			[113.583285,37.861188],
			[113.576726, 22.270715]
		]
	}, {
		"fromName": "阳泉",
		"toName": "铁岭",
		"coords": [
			[113.583285,37.861188],
			[123.726166, 42.223769]
		]
	}, {
		"fromName": "阳泉",
		"toName": "蓬莱",
		"coords": [
			[113.583285,37.861188],
			[120.758848, 37.810661]
		]
	}, {
		"fromName": "阳泉",
		"toName": "天津",
		"coords": [
			[113.583285,37.861188],
			[117.200983, 39.084158]
		]
	}, {
		"fromName": "阳泉",
		"toName": "天津",
		"coords": [
			[113.583285,37.861188],
			[117.200983, 39.084158]
		]
	}, {
		"fromName": "阳泉",
		"toName": "宁波",
		"coords": [
			[113.583285,37.861188],
			[121.550357, 29.874557]
		]
	}, {
		"fromName": "阳泉",
		"toName": "上海",
		"coords": [
			[113.583285,37.861188],
			[121.473701, 31.230416]
		]
	}, {
		"fromName": "阳泉",
		"toName": "佛山",
		"coords": [
			[113.583285,37.861188],
			[113.121416, 23.021548]
		]
	}, {
		"fromName": "阳泉",
		"toName": "长宁",
		"coords": [
			[113.583285,37.861188],
			[121.424624, 31.220367]
		]
	}, {
		"fromName": "阳泉",
		"toName": "珲春",
		"coords": [
			[113.583285,37.861188],
			[130.366036, 42.862821]
		]
	}, {
		"fromName": "阳泉",
		"toName": "黄浦",
		"coords": [
			[113.583285,37.861188],
			[121.484443, 31.231763]
		]
	}, {
		"fromName": "阳泉",
		"toName": "威海",
		"coords": [
			[113.583285,37.861188],
			[122.12042, 37.513068]
		]
	}, {
		"fromName": "阳泉",
		"toName": "长春",
		"coords": [
			[113.583285,37.861188],
			[125.323544, 43.817072]
		]
	}, {
		"fromName": "阳泉",
		"toName": "上海",
		"coords": [
			[113.583285,37.861188],
			[121.473701, 31.230416]
		]
	}, {
		"fromName": "阳泉",
		"toName": "鸡西",
		"coords": [
			[113.583285,37.861188],
			[130.969333, 45.295075]
		]
	}, {
		"fromName": "阳泉",
		"toName": "呼和浩特",
		"coords": [
			[113.583285,37.861188],
			[111.749181, 40.842585]
		]
	}, {
		"fromName": "阳泉",
		"toName": "连云港",
		"coords": [
			[113.583285,37.861188],
			[119.221611, 34.596653]
		]
	}, {
		"fromName": "阳泉",
		"toName": "杭州",
		"coords": [
			[113.583285,37.861188],
			[120.15507, 30.274085]
		]
	}, {
		"fromName": "阳泉",
		"toName": "嘉兴",
		"coords": [
			[113.583285,37.861188],
			[120.755486, 30.746129]
		]
	}, {
		"fromName": "阳泉",
		"toName": "盘锦",
		"coords": [
			[113.583285,37.861188],
			[122.070714, 41.119997]
		]
	}, {
		"fromName": "阳泉",
		"toName": "同江",
		"coords": [
			[113.583285,37.861188],
			[132.510919, 47.642707]
		]
	}, {
		"fromName": "阳泉",
		"toName": "杭州",
		"coords": [
			[113.583285,37.861188],
			[120.15507, 30.274085]
		]
	}, {
		"fromName": "阳泉",
		"toName": "舟山",
		"coords": [
			[113.583285,37.861188],
			[122.207216, 29.985295]
		]
	}, {
		"fromName": "阳泉",
		"toName": "大连",
		"coords": [
			[113.583285,37.861188],
			[121.614682, 38.914003]
		]
	}, {
		"fromName": "阳泉",
		"toName": "绵阳",
		"coords": [
			[113.583285,37.861188],
			[104.679114, 31.46745]
		]
	}, {
		"fromName": "阳泉",
		"toName": "溆浦",
		"coords": [
			[113.583285,37.861188],
			[110.594921, 27.908281]
		]
	}, {
		"fromName": "阳泉",
		"toName": "朝阳",
		"coords": [
			[113.583285,37.861188],
			[116.443108, 39.92147]
		]
	}, {
		"fromName": "朔州",
		"toName": "无锡",
		"coords": [
			[112.433387,39.331261],
			[120.31191, 31.49117]
		]
	}, {
		"fromName": "朔州",
		"toName": "沈阳",
		"coords": [
			[112.433387,39.331261],
			[123.431475, 41.805698]
		]
	}, {
		"fromName": "朔州",
		"toName": "湖里",
		"coords": [
			[112.433387,39.331261],
			[118.146769, 24.512905]
		]
	}, {
		"fromName": "朔州",
		"toName": "无锡",
		"coords": [
			[112.433387,39.331261],
			[120.31191, 31.49117]
		]
	}, {
		"fromName": "朔州",
		"toName": "长宁",
		"coords": [
			[112.433387,39.331261],
			[121.424624, 31.220367]
		]
	}, {
		"fromName": "朔州",
		"toName": "胶州",
		"coords": [
			[112.433387,39.331261],
			[120.033382, 36.26468]
		]
	}, {
		"fromName": "朔州",
		"toName": "青岛",
		"coords": [
			[112.433387,39.331261],
			[120.38264, 36.067082]
		]
	}, {
		"fromName": "朔州",
		"toName": "海淀",
		"coords": [
			[112.433387,39.331261],
			[116.298056, 39.959912]
		]
	}, {
		"fromName": "朔州",
		"toName": "厦门",
		"coords": [
			[112.433387,39.331261],
			[118.089425, 24.479834]
		]
	}, {
		"fromName": "朔州",
		"toName": "中山",
		"coords": [
			[112.433387,39.331261],
			[113.392782, 22.517646]
		]
	}, {
		"fromName": "朔州",
		"toName": "太原",
		"coords": [
			[112.433387,39.331261],
			[112.548879, 37.87059]
		]
	}, {
		"fromName": "朔州",
		"toName": "吉林",
		"coords": [
			[112.433387,39.331261],
			[126.549572, 43.837883]
		]
	}, {
		"fromName": "朔州",
		"toName": "武侯",
		"coords": [
			[112.433387,39.331261],
			[104.04339, 30.641982]
		]
	}, {
		"fromName": "朔州",
		"toName": "廊坊",
		"coords": [
			[112.433387,39.331261],
			[116.683752, 39.538047]
		]
	}, {
		"fromName": "朔州",
		"toName": "临汾",
		"coords": [
			[112.433387,39.331261],
			[111.518976, 36.088005]
		]
	}, {
		"fromName": "朔州",
		"toName": "天津",
		"coords": [
			[112.433387,39.331261],
			[117.200983, 39.084158]
		]
	}, {
		"fromName": "朔州",
		"toName": "泉州",
		"coords": [
			[112.433387,39.331261],
			[118.675676, 24.874132]
		]
	}, {
		"fromName": "朔州",
		"toName": "温州",
		"coords": [
			[112.433387,39.331261],
			[120.699367, 27.994267]
		]
	}, {
		"fromName": "朔州",
		"toName": "唐山",
		"coords": [
			[112.433387,39.331261],
			[118.180194, 39.630867]
		]
	}, {
		"fromName": "朔州",
		"toName": "铁岭",
		"coords": [
			[112.433387,39.331261],
			[123.726166, 42.223769]
		]
	}, {
		"fromName": "朔州",
		"toName": "即墨",
		"coords": [
			[112.433387,39.331261],
			[120.447128, 36.389639]
		]
	}, {
		"fromName": "朔州",
		"toName": "上海",
		"coords": [
			[112.433387,39.331261],
			[121.473701, 31.230416]
		]
	}, {
		"fromName": "朔州",
		"toName": "广州",
		"coords": [
			[112.433387,39.331261],
			[113.264435, 23.129163]
		]
	}, {
		"fromName": "朔州",
		"toName": "廊坊",
		"coords": [
			[112.433387,39.331261],
			[116.683752, 39.538047]
		]
	}, {
		"fromName": "朔州",
		"toName": "荣成",
		"coords": [
			[112.433387,39.331261],
			[122.486658, 37.16516]
		]
	}, {
		"fromName": "朔州",
		"toName": "海城",
		"coords": [
			[112.433387,39.331261],
			[122.685217, 40.882377]
		]
	}, {
		"fromName": "朔州",
		"toName": "沈阳",
		"coords": [
			[112.433387,39.331261],
			[123.431475, 41.805698]
		]
	}, {
		"fromName": "朔州",
		"toName": "青岛",
		"coords": [
			[112.433387,39.331261],
			[120.38264, 36.067082]
		]
	}, {
		"fromName": "朔州",
		"toName": "大连",
		"coords": [
			[112.433387,39.331261],
			[121.614682, 38.914003]
		]
	}, {
		"fromName": "朔州",
		"toName": "珠海",
		"coords": [
			[112.433387,39.331261],
			[113.576726, 22.270715]
		]
	}, {
		"fromName": "朔州",
		"toName": "房山",
		"coords": [
			[112.433387,39.331261],
			[116.143267, 39.749144]
		]
	}, {
		"fromName": "朔州",
		"toName": "金坛",
		"coords": [
			[112.433387,39.331261],
			[119.597897, 31.723247]
		]
	}, {
		"fromName": "朔州",
		"toName": "齐齐哈尔",
		"coords": [
			[112.433387,39.331261],
			[126.661669, 45.742347]
		]
	}, {
		"fromName": "朔州",
		"toName": "大兴",
		"coords": [
			[112.433387,39.331261],
			[116.341395, 39.726929]
		]
	}, {
		"fromName": "朔州",
		"toName": "密云",
		"coords": [
			[112.433387,39.331261],
			[116.801346, 40.35874]
		]
	}, {
		"fromName": "朔州",
		"toName": "和平",
		"coords": [
			[112.433387,39.331261],
			[117.21451, 39.116949]
		]
	}, {
		"fromName": "朔州",
		"toName": "龙井",
		"coords": [
			[112.433387,39.331261],
			[129.427066, 42.766311]
		]
	}, {
		"fromName": "朔州",
		"toName": "道里",
		"coords": [
			[112.433387,39.331261],
			[126.616957, 45.755777]
		]
	}, {
		"fromName": "朔州",
		"toName": "武汉",
		"coords": [
			[112.433387,39.331261],
			[114.305393, 30.593099]
		]
	}, {
		"fromName": "朔州",
		"toName": "常熟",
		"coords": [
			[112.433387,39.331261],
			[120.752481, 31.654376]
		]
	}, {
		"fromName": "朔州",
		"toName": "烟台",
		"coords": [
			[112.433387,39.331261],
			[121.447935, 37.463822]
		]
	}, {
		"fromName": "朔州",
		"toName": "西藏",
		"coords": [
			[112.433387,39.331261],
			[91.117212, 29.646923]
		]
	}, {
		"fromName": "大同",
		"toName": "长沙",
		"coords": [
			[113.295259,40.09031],
			[112.938814, 28.228209]
		]
	}, {
		"fromName": "大同",
		"toName": "石家庄",
		"coords": [
			[113.295259,40.09031],
			[114.51486, 38.042307]
		]
	}, {
		"fromName": "大同",
		"toName": "佛山",
		"coords": [
			[113.295259,40.09031],
			[113.121416, 23.021548]
		]
	}, {
		"fromName": "大同",
		"toName": "黄骅",
		"coords": [
			[113.295259,40.09031],
			[117.330048, 38.371383]
		]
	}, {
		"fromName": "大同",
		"toName": "中山",
		"coords": [
			[113.295259,40.09031],
			[113.392782, 22.517646]
		]
	}, {
		"fromName": "大同",
		"toName": "北京",
		"coords": [
			[113.295259,40.09031],
			[116.407526, 39.90403]
		]
	}, {
		"fromName": "大同",
		"toName": "三河",
		"coords": [
			[113.295259,40.09031],
			[117.078295, 39.982718]
		]
	}, {
		"fromName": "大同",
		"toName": "庆安",
		"coords": [
			[113.295259,40.09031],
			[127.507825, 46.880102]
		]
	}, {
		"fromName": "大同",
		"toName": "长沙",
		"coords": [
			[113.295259,40.09031],
			[112.938814, 28.228209]
		]
	}, {
		"fromName": "大同",
		"toName": "西安",
		"coords": [
			[113.295259,40.09031],
			[108.940175, 34.341568]
		]
	}, {
		"fromName": "大同",
		"toName": "朝阳",
		"coords": [
			[113.295259,40.09031],
			[116.443108, 39.92147]
		]
	}, {
		"fromName": "大同",
		"toName": "丰台",
		"coords": [
			[113.295259,40.09031],
			[116.287149, 39.858427]
		]
	}, {
		"fromName": "大同",
		"toName": "延吉",
		"coords": [
			[113.295259,40.09031],
			[129.508946, 42.891255]
		]
	}, {
		"fromName": "大同",
		"toName": "长春",
		"coords": [
			[113.295259,40.09031],
			[125.323544, 43.817072]
		]
	}, {
		"fromName": "大同",
		"toName": "天津",
		"coords": [
			[113.295259,40.09031],
			[117.200983, 39.084158]
		]
	}, {
		"fromName": "大同",
		"toName": "昌平",
		"coords": [
			[113.295259,40.09031],
			[116.231204, 40.22066]
		]
	}, {
		"fromName": "大同",
		"toName": "赣州",
		"coords": [
			[113.295259,40.09031],
			[114.93503, 25.831829]
		]
	}, {
		"fromName": "大同",
		"toName": "厦门",
		"coords": [
			[113.295259,40.09031],
			[118.089425, 24.479834]
		]
	}, {
		"fromName": "大同",
		"toName": "秦皇岛",
		"coords": [
			[113.295259,40.09031],
			[119.600493, 39.935385]
		]
	}, {
		"fromName": "大同",
		"toName": "菏泽",
		"coords": [
			[113.295259,40.09031],
			[115.480656, 35.23375]
		]
	}, {
		"fromName": "大同",
		"toName": "闵行",
		"coords": [
			[113.295259,40.09031],
			[121.381709, 31.112813]
		]
	}, {
		"fromName": "大同",
		"toName": "石景山",
		"coords": [
			[113.295259,40.09031],
			[116.222982, 39.906611]
		]
	}, {
		"fromName": "大同",
		"toName": "珠海",
		"coords": [
			[113.295259,40.09031],
			[113.576726, 22.270715]
		]
	}, {
		"fromName": "大同",
		"toName": "青岛",
		"coords": [
			[113.295259,40.09031],
			[120.38264, 36.067082]
		]
	}, {
		"fromName": "大同",
		"toName": "海门",
		"coords": [
			[113.295259,40.09031],
			[121.181615, 31.871173]
		]
	}, {
		"fromName": "大同",
		"toName": "长春",
		"coords": [
			[113.295259,40.09031],
			[125.323544, 43.817072]
		]
	}, {
		"fromName": "大同",
		"toName": "城阳",
		"coords": [
			[113.295259,40.09031],
			[120.39631, 36.307064]
		]
	}, {
		"fromName": "大同",
		"toName": "大同",
		"coords": [
			[113.295259,40.09031],
			[113.61244, 40.040295]
		]
	}, {
		"fromName": "大同",
		"toName": "邢台",
		"coords": [
			[113.295259,40.09031],
			[114.504844, 37.070589]
		]
	}, {
		"fromName": "大同",
		"toName": "胶州",
		"coords": [
			[113.295259,40.09031],
			[120.033382, 36.26468]
		]
	}, {
		"fromName": "大同",
		"toName": "重庆",
		"coords": [
			[113.295259,40.09031],
			[106.551557, 29.56301]
		]
	}, {
		"fromName": "大同",
		"toName": "佳木斯",
		"coords": [
			[113.295259,40.09031],
			[130.318917, 46.799923]
		]
	}, {
		"fromName": "大同",
		"toName": "大连",
		"coords": [
			[113.295259,40.09031],
			[121.614682, 38.914003]
		]
	}, {
		"fromName": "大同",
		"toName": "南京",
		"coords": [
			[113.295259,40.09031],
			[118.796877, 32.060255]
		]
	}, {
		"fromName": "大同",
		"toName": "日照",
		"coords": [
			[113.295259,40.09031],
			[119.526888, 35.416377]
		]
	}, {
		"fromName": "大同",
		"toName": "鸡东",
		"coords": [
			[113.295259,40.09031],
			[131.12408, 45.260412]
		]
	}, {
		"fromName": "大同",
		"toName": "即墨",
		"coords": [
			[113.295259,40.09031],
			[120.447128, 36.389639]
		]
	}, {
		"fromName": "大同",
		"toName": "朝阳",
		"coords": [
			[113.295259,40.09031],
			[116.443108, 39.92147]
		]
	}, {
		"fromName": "大同",
		"toName": "南通",
		"coords": [
			[113.295259,40.09031],
			[120.894291, 31.980172]
		]
	}, {
		"fromName": "大同",
		"toName": "张家港",
		"coords": [
			[113.295259,40.09031],
			[120.553284, 31.870367]
		]
	}, {
		"fromName": "大同",
		"toName": "三河",
		"coords": [
			[113.295259,40.09031],
			[117.078295, 39.982718]
		]
	}, {
		"fromName": "大同",
		"toName": "咸阳",
		"coords": [
			[113.295259,40.09031],
			[108.708991, 34.329605]
		]
	}, {
		"fromName": "大同",
		"toName": "中山",
		"coords": [
			[113.295259,40.09031],
			[113.392782, 22.517646]
		]
	}, {
		"fromName": "大同",
		"toName": "胶州",
		"coords": [
			[113.295259,40.09031],
			[120.033382, 36.26468]
		]
	}]
};
let shanxiData = {
	citys:[
		{
			"name": "太原市",
			"value": [112.549248, 37.857014, 2],
			"symbolSize": 10,
			"itemStyle": {
				"color": "#F58158"
			}
		}, {
			"name": "大同市",
			"value": [113.295259, 40.09031, 2],
			"symbolSize": 10,
			"itemStyle": {
				"color": "#F58158"
			},
			children:[{
				name:'李家窑煤业有限公司'
			},{
				name:'草垛沟煤业有限公司'
			},{
				name:'高山煤业有限公司'
			},{
				name:'和尚嘴煤业有限公司'
			},{
				name:'新旺煤业有限公司'
			}]
		}, {
			"name": "朔州市",
			"value": [112.433387, 39.331261, 4],
			"symbolSize": 10,
			"itemStyle": {
				"color": "#F58158"
			}
		}, {
			"name": "晋中市",
			"value": [112.736465, 37.696495, 1],
			"symbolSize": 10,
			"itemStyle": {
				"color": "#F58158"
			}
		}
	]
}
echarts.registerMap('shanxi',shanxi)
export let option8 = {

	title: {
		text: '',
		left: 'center',
		textStyle: {
			color: '#fff'
		}
	},
	legend: {
		show: false,
		orient: 'vertical',
		top: 'bottom',
		left: 'right',
		data: ['地点'],
		textStyle: {
			color: '#fff'
		}
	},
	geo: {
		map: 'shanxi',
		roam: true,
		itemStyle: {
			areaColor: 'rgba(7,21,57,0.5)',
			borderColor: '#0177ff'
		},
		emphasis: {
			itemStyle:{
				areaColor: '#071537', //鼠标指上市时的颜色,
			},
			label:{
				color:'#ffffff'
			}
		},
		
	},
	tooltip: {
		show: true,
    trigger: 'item',
		triggerOn: "click",
		alwaysShowContent: true,
		enterable: true,
		className:'toolTipDom',
		backgroundColor:'rgba(6,20,54,0.8)',
		borderColor:'#07A5FC',
		formatter: function(params) {
			let html = '';
			let data = params.data.children
			if(Array.isArray(data)){
				for(let i=0;i<data.length;i++){
					html += '<p class="inf">'+data[i].name+'</p>'
				}
				html+='<button class="automation">自动化</button><button class="information">信息化</button>'
			}
			return html;
		},
  },
	series: [{
		name: '地点',
		type: 'effectScatter',
		coordinateSystem: 'geo',
		zlevel: 2,
		rippleEffect: {
			brushType: 'stroke'
		},
		symbolSize: 10,
		showEffectOn: 'render',
		itemStyle: {
			color: '#46bee9'
		},
		emphasis: {
			label: {
				show: true,
				position: 'top',
				color:'#ffffff',
				formatter: '{b}'
			}
		},
		data: shanxiData.citys
	}]
};


//收费站收费量
var geoCoordMap = {
	"海门": [121.15, 31.89],
	"鄂尔多斯": [109.781327, 39.608266],
	"招远": [120.38, 37.35],
	"舟山": [122.207216, 29.985295],
	"齐齐哈尔": [123.97, 47.33],
	"盐城": [120.13, 33.38],
	"赤峰": [118.87, 42.28],
	"青岛": [120.33, 36.07],
	"乳山": [121.52, 36.89],
	"金昌": [102.188043, 38.520089],
	"泉州": [118.58, 24.93],
	"莱西": [120.53, 36.86],
	"日照": [119.46, 35.42],
	"胶南": [119.97, 35.88],
	"南通": [121.05, 32.08],
	"拉萨": [91.11, 29.97],
	"云浮": [112.02, 22.93],
	"梅州": [116.1, 24.55],
	"文登": [122.05, 37.2],
	"上海": [121.48, 31.22],
	"攀枝花": [101.718637, 26.582347],
	"威海": [122.1, 37.5],
	"承德": [117.93, 40.97],
	"厦门": [118.1, 24.46],
	"汕尾": [115.375279, 22.786211],
	"潮州": [116.63, 23.68],
	"丹东": [124.37, 40.13],
	"太仓": [121.1, 31.45],
	"曲靖": [103.79, 25.51],
	"烟台": [121.39, 37.52],
	"福州": [119.3, 26.08],
	"瓦房店": [121.979603, 39.627114],
	"即墨": [120.45, 36.38],
	"抚顺": [123.97, 41.97],
	"玉溪": [102.52, 24.35],
	"张家口": [114.87, 40.82],
	"阳泉": [113.57, 37.85],
	"莱州": [119.942327, 37.177017],
	"湖州": [120.1, 30.86],
	"汕头": [116.69, 23.39],
	"昆山": [120.95, 31.39],
	"宁波": [121.56, 29.86],
	"湛江": [110.359377, 21.270708],
	"揭阳": [116.35, 23.55],
	"荣成": [122.41, 37.16],
	"连云港": [119.16, 34.59],
	"葫芦岛": [120.836932, 40.711052],
	"常熟": [120.74, 31.64],
	"东莞": [113.75, 23.04],
	"河源": [114.68, 23.73],
	"淮安": [119.15, 33.5],
	"泰州": [119.9, 32.49],
	"南宁": [108.33, 22.84],
	"营口": [122.18, 40.65],
	"惠州": [114.4, 23.09],
	"江阴": [120.26, 31.91],
	"蓬莱": [120.75, 37.8],
	"韶关": [113.62, 24.84],
	"嘉峪关": [98.289152, 39.77313],
	"广州": [113.23, 23.16],
	"延安": [109.47, 36.6],
	"太原": [112.53, 37.87],
	"清远": [113.01, 23.7],
	"中山": [113.38, 22.52],
	"昆明": [102.73, 25.04],
	"寿光": [118.73, 36.86],
	"盘锦": [122.070714, 41.119997],
	"长治": [113.08, 36.18],
	"深圳": [114.07, 22.62],
	"珠海": [113.52, 22.3],
	"宿迁": [118.3, 33.96],
	"咸阳": [108.72, 34.36],
	"铜川": [109.11, 35.09],
	"平度": [119.97, 36.77],
	"佛山": [113.11, 23.05],
	"海口": [110.35, 20.02],
	"江门": [113.06, 22.61],
	"章丘": [117.53, 36.72],
	"肇庆": [112.44, 23.05],
	"大连": [121.62, 38.92],
	"临汾": [111.5, 36.08],
	"吴江": [120.63, 31.16],
	"石嘴山": [106.39, 39.04],
	"沈阳": [123.38, 41.8],
	"苏州": [120.62, 31.32],
	"茂名": [110.88, 21.68],
	"嘉兴": [120.76, 30.77],
	"长春": [125.35, 43.88],
	"胶州": [120.03336, 36.264622],
	"银川": [106.27, 38.47],
	"张家港": [120.555821, 31.875428],
	"三门峡": [111.19, 34.76],
	"锦州": [121.15, 41.13],
	"南昌": [115.89, 28.68],
	"柳州": [109.4, 24.33],
	"三亚": [109.511909, 18.252847],
	"自贡": [104.778442, 29.33903],
	"吉林": [126.57, 43.87],
	"阳江": [111.95, 21.85],
	"泸州": [105.39, 28.91],
	"西宁": [101.74, 36.56],
	"宜宾": [104.56, 29.77],
	"呼和浩特": [111.65, 40.82],
	"成都": [104.06, 30.67],
	"大同": [113.3, 40.12],
	"镇江": [119.44, 32.2],
	"桂林": [110.28, 25.29],
	"张家界": [110.479191, 29.117096],
	"宜兴": [119.82, 31.36],
	"北海": [109.12, 21.49],
	"西安": [108.95, 34.27],
	"金坛": [119.56, 31.74],
	"东营": [118.49, 37.46],
	"牡丹江": [129.58, 44.6],
	"遵义": [106.9, 27.7],
	"绍兴": [120.58, 30.01],
	"扬州": [119.42, 32.39],
	"常州": [119.95, 31.79],
	"潍坊": [119.1, 36.62],
	"重庆": [106.54, 29.59],
	"台州": [121.420757, 28.656386],
	"南京": [118.78, 32.04],
	"滨州": [118.03, 37.36],
	"贵阳": [106.71, 26.57],
	"无锡": [120.29, 31.59],
	"本溪": [123.73, 41.3],
	"克拉玛依": [84.77, 45.59],
	"渭南": [109.5, 34.52],
	"马鞍山": [118.48, 31.56],
	"宝鸡": [107.15, 34.38],
	"焦作": [113.21, 35.24],
	"句容": [119.16, 31.95],
	"北京": [116.46, 39.92],
	"徐州": [117.2, 34.26],
	"衡水": [115.72, 37.72],
	"包头": [110, 40.58],
	"绵阳": [104.73, 31.48],
	"乌鲁木齐": [87.68, 43.77],
	"枣庄": [117.57, 34.86],
	"杭州": [120.19, 30.26],
	"淄博": [118.05, 36.78],
	"鞍山": [122.85, 41.12],
	"溧阳": [119.48, 31.43],
	"库尔勒": [86.06, 41.68],
	"安阳": [114.35, 36.1],
	"开封": [114.35, 34.79],
	"济南": [117, 36.65],
	"德阳": [104.37, 31.13],
	"温州": [120.65, 28.01],
	"九江": [115.97, 29.71],
	"邯郸": [114.47, 36.6],
	"临安": [119.72, 30.23],
	"兰州": [103.73, 36.03],
	"沧州": [116.83, 38.33],
	"临沂": [118.35, 35.05],
	"南充": [106.110698, 30.837793],
	"天津": [117.2, 39.13],
	"富阳": [119.95, 30.07],
	"泰安": [117.13, 36.18],
	"诸暨": [120.23, 29.71],
	"郑州": [113.65, 34.76],
	"哈尔滨": [126.63, 45.75],
	"聊城": [115.97, 36.45],
	"芜湖": [118.38, 31.33],
	"唐山": [118.02, 39.63],
	"平顶山": [113.29, 33.75],
	"邢台": [114.48, 37.05],
	"德州": [116.29, 37.45],
	"济宁": [116.59, 35.38],
	"荆州": [112.239741, 30.335165],
	"宜昌": [111.3, 30.7],
	"义乌": [120.06, 29.32],
	"丽水": [119.92, 28.45],
	"洛阳": [112.44, 34.7],
	"秦皇岛": [119.57, 39.95],
	"株洲": [113.16, 27.83],
	"石家庄": [114.48, 38.03],
	"莱芜": [117.67, 36.19],
	"常德": [111.69, 29.05],
	"保定": [115.48, 38.85],
	"湘潭": [112.91, 27.87],
	"金华": [119.64, 29.12],
	"岳阳": [113.09, 29.37],
	"长沙": [113, 28.21],
	"衢州": [118.88, 28.97],
	"廊坊": [116.7, 39.53],
	"菏泽": [115.480656, 35.23375],
	"合肥": [117.27, 31.86],
	"武汉": [114.31, 30.52],
	"大庆": [125.03, 46.58]
};

var convertData = function(data) {
	var res = [];
	for (var i = 0; i < data.length; i++) {
		var geoCoord = geoCoordMap[data[i].name];
		if (geoCoord) {
			res.push({
				name: data[i].name,
				value: geoCoord.concat(data[i].value)
			});
		}
	}
	return res;
};

export let option9 = {

	title: {
		text: ' ',
		sublink: ' ',
		x: 'center',
		textStyle: {
			color: '#fff'
		}
	},
	tooltip: {
		trigger: 'item',
		formatter: function(params) {
			return params.name + ' : ' + params.value[2];
		}
	},
	legend: {
		orient: 'vertical',
		y: 'bottom',
		x: 'right',
		textStyle: {
			color: '#fff'
		}
	},
	visualMap: {
		min: 0,
		max: 200,
		calculable: true,
		color: ['#d94e5d', '#eac736', '#50a3ba'],
		textStyle: {
			color: '#fff'
		}
	},
	geo: {
		map: 'china',
		itemStyle: {
			areaColor: 'rgba(127,199,221,0.1)',
			borderColor: '#0177ff'
		},
		emphasis: {
			areaColor: '#071537', //鼠标指上市时的颜色
			label:{
				show: false
			}
		}
	},
	series: [{
		name: ' ',
		type: 'scatter',
		coordinateSystem: 'geo',
		data: convertData([{
				name: "海门",
				value: 9
			},
			{
				name: "鄂尔多斯",
				value: 12
			},
			{
				name: "招远",
				value: 12
			},
			{
				name: "舟山",
				value: 12
			},
			{
				name: "齐齐哈尔",
				value: 14
			},
			{
				name: "盐城",
				value: 15
			},
			{
				name: "赤峰",
				value: 16
			},
			{
				name: "青岛",
				value: 18
			},
			{
				name: "乳山",
				value: 18
			},
			{
				name: "金昌",
				value: 19
			},
			{
				name: "泉州",
				value: 21
			},
			{
				name: "莱西",
				value: 21
			},
			{
				name: "日照",
				value: 21
			},
			{
				name: "胶南",
				value: 22
			},
			{
				name: "南通",
				value: 23
			},
			{
				name: "拉萨",
				value: 24
			},
			{
				name: "云浮",
				value: 24
			},
			{
				name: "梅州",
				value: 25
			},
			{
				name: "文登",
				value: 25
			},
			{
				name: "上海",
				value: 25
			},
			{
				name: "攀枝花",
				value: 25
			},
			{
				name: "威海",
				value: 25
			},
			{
				name: "承德",
				value: 25
			},
			{
				name: "厦门",
				value: 26
			},
			{
				name: "汕尾",
				value: 26
			},
			{
				name: "潮州",
				value: 26
			},
			{
				name: "丹东",
				value: 27
			},
			{
				name: "太仓",
				value: 27
			},
			{
				name: "曲靖",
				value: 27
			},
			{
				name: "烟台",
				value: 28
			},
			{
				name: "福州",
				value: 29
			},
			{
				name: "瓦房店",
				value: 30
			},
			{
				name: "即墨",
				value: 30
			},
			{
				name: "抚顺",
				value: 31
			},
			{
				name: "玉溪",
				value: 31
			},
			{
				name: "张家口",
				value: 31
			},
			{
				name: "阳泉",
				value: 31
			},
			{
				name: "莱州",
				value: 32
			},
			{
				name: "湖州",
				value: 32
			},
			{
				name: "汕头",
				value: 32
			},
			{
				name: "昆山",
				value: 33
			},
			{
				name: "宁波",
				value: 33
			},
			{
				name: "湛江",
				value: 33
			},
			{
				name: "揭阳",
				value: 34
			},
			{
				name: "荣成",
				value: 34
			},
			{
				name: "连云港",
				value: 35
			},
			{
				name: "葫芦岛",
				value: 35
			},
			{
				name: "常熟",
				value: 36
			},
			{
				name: "东莞",
				value: 36
			},
			{
				name: "河源",
				value: 36
			},
			{
				name: "淮安",
				value: 36
			},
			{
				name: "泰州",
				value: 36
			},
			{
				name: "南宁",
				value: 37
			},
			{
				name: "营口",
				value: 37
			},
			{
				name: "惠州",
				value: 37
			},
			{
				name: "江阴",
				value: 37
			},
			{
				name: "蓬莱",
				value: 37
			},
			{
				name: "韶关",
				value: 38
			},
			{
				name: "嘉峪关",
				value: 38
			},
			{
				name: "广州",
				value: 38
			},
			{
				name: "延安",
				value: 38
			},
			{
				name: "太原",
				value: 39
			},
			{
				name: "清远",
				value: 39
			},
			{
				name: "中山",
				value: 39
			},
			{
				name: "昆明",
				value: 39
			},
			{
				name: "寿光",
				value: 40
			},
			{
				name: "盘锦",
				value: 40
			},
			{
				name: "长治",
				value: 41
			},
			{
				name: "深圳",
				value: 41
			},
			{
				name: "珠海",
				value: 42
			},
			{
				name: "宿迁",
				value: 43
			},
			{
				name: "咸阳",
				value: 43
			},
			{
				name: "铜川",
				value: 44
			},
			{
				name: "平度",
				value: 44
			},
			{
				name: "佛山",
				value: 44
			},
			{
				name: "海口",
				value: 44
			},
			{
				name: "江门",
				value: 45
			},
			{
				name: "章丘",
				value: 45
			},
			{
				name: "肇庆",
				value: 46
			},
			{
				name: "大连",
				value: 47
			},
			{
				name: "临汾",
				value: 47
			},
			{
				name: "吴江",
				value: 47
			},
			{
				name: "石嘴山",
				value: 49
			},
			{
				name: "沈阳",
				value: 50
			},
			{
				name: "苏州",
				value: 50
			},
			{
				name: "茂名",
				value: 50
			},
			{
				name: "嘉兴",
				value: 51
			},
			{
				name: "长春",
				value: 51
			},
			{
				name: "胶州",
				value: 52
			},
			{
				name: "银川",
				value: 52
			},
			{
				name: "张家港",
				value: 52
			},
			{
				name: "三门峡",
				value: 53
			},
			{
				name: "锦州",
				value: 54
			},
			{
				name: "南昌",
				value: 54
			},
			{
				name: "柳州",
				value: 54
			},
			{
				name: "三亚",
				value: 54
			},
			{
				name: "自贡",
				value: 56
			},
			{
				name: "吉林",
				value: 56
			},
			{
				name: "阳江",
				value: 57
			},
			{
				name: "泸州",
				value: 57
			},
			{
				name: "西宁",
				value: 57
			},
			{
				name: "宜宾",
				value: 58
			},
			{
				name: "呼和浩特",
				value: 58
			},
			{
				name: "成都",
				value: 58
			},
			{
				name: "大同",
				value: 58
			},
			{
				name: "镇江",
				value: 59
			},
			{
				name: "桂林",
				value: 59
			},
			{
				name: "张家界",
				value: 59
			},
			{
				name: "宜兴",
				value: 59
			},
			{
				name: "北海",
				value: 60
			},
			{
				name: "西安",
				value: 61
			},
			{
				name: "金坛",
				value: 62
			},
			{
				name: "东营",
				value: 62
			},
			{
				name: "牡丹江",
				value: 63
			},
			{
				name: "遵义",
				value: 63
			},
			{
				name: "绍兴",
				value: 63
			},
			{
				name: "扬州",
				value: 64
			},
			{
				name: "常州",
				value: 64
			},
			{
				name: "潍坊",
				value: 65
			},
			{
				name: "重庆",
				value: 66
			},
			{
				name: "台州",
				value: 67
			},
			{
				name: "南京",
				value: 67
			},
			{
				name: "滨州",
				value: 70
			},
			{
				name: "贵阳",
				value: 71
			},
			{
				name: "无锡",
				value: 71
			},
			{
				name: "本溪",
				value: 71
			},
			{
				name: "克拉玛依",
				value: 72
			},
			{
				name: "渭南",
				value: 72
			},
			{
				name: "马鞍山",
				value: 72
			},
			{
				name: "宝鸡",
				value: 72
			},
			{
				name: "焦作",
				value: 75
			},
			{
				name: "句容",
				value: 75
			},
			{
				name: "北京",
				value: 79
			},
			{
				name: "徐州",
				value: 79
			},
			{
				name: "衡水",
				value: 80
			},
			{
				name: "包头",
				value: 80
			},
			{
				name: "绵阳",
				value: 80
			},
			{
				name: "乌鲁木齐",
				value: 84
			},
			{
				name: "枣庄",
				value: 84
			},
			{
				name: "杭州",
				value: 84
			},
			{
				name: "淄博",
				value: 85
			},
			{
				name: "鞍山",
				value: 86
			},
			{
				name: "溧阳",
				value: 86
			},
			{
				name: "库尔勒",
				value: 86
			},
			{
				name: "安阳",
				value: 90
			},
			{
				name: "开封",
				value: 90
			},
			{
				name: "济南",
				value: 92
			},
			{
				name: "德阳",
				value: 93
			},
			{
				name: "温州",
				value: 95
			},
			{
				name: "九江",
				value: 96
			},
			{
				name: "邯郸",
				value: 98
			},
			{
				name: "临安",
				value: 99
			},
			{
				name: "兰州",
				value: 99
			},
			{
				name: "沧州",
				value: 100
			},
			{
				name: "临沂",
				value: 103
			},
			{
				name: "南充",
				value: 104
			},
			{
				name: "天津",
				value: 105
			},
			{
				name: "富阳",
				value: 106
			},
			{
				name: "泰安",
				value: 112
			},
			{
				name: "诸暨",
				value: 112
			},
			{
				name: "郑州",
				value: 113
			},
			{
				name: "哈尔滨",
				value: 114
			},
			{
				name: "聊城",
				value: 116
			},
			{
				name: "芜湖",
				value: 117
			},
			{
				name: "唐山",
				value: 119
			},
			{
				name: "平顶山",
				value: 119
			},
			{
				name: "邢台",
				value: 119
			},
			{
				name: "德州",
				value: 120
			},
			{
				name: "济宁",
				value: 120
			},
			{
				name: "荆州",
				value: 127
			},
			{
				name: "宜昌",
				value: 130
			},
			{
				name: "义乌",
				value: 132
			},
			{
				name: "丽水",
				value: 133
			},
			{
				name: "洛阳",
				value: 134
			},
			{
				name: "秦皇岛",
				value: 136
			},
			{
				name: "株洲",
				value: 143
			},
			{
				name: "石家庄",
				value: 147
			},
			{
				name: "莱芜",
				value: 148
			},
			{
				name: "常德",
				value: 152
			},
			{
				name: "保定",
				value: 153
			},
			{
				name: "湘潭",
				value: 154
			},
			{
				name: "金华",
				value: 157
			},
			{
				name: "岳阳",
				value: 169
			},
			{
				name: "长沙",
				value: 175
			},
			{
				name: "衢州",
				value: 177
			},
			{
				name: "廊坊",
				value: 193
			},
			{
				name: "菏泽",
				value: 194
			},
			{
				name: "合肥",
				value: 229
			},
			{
				name: "武汉",
				value: 273
			},
			{
				name: "大庆",
				value: 279
			}
		]),
		symbolSize: 12,
		label: {
			show: false
		},
		emphasis: {
			borderColor: '#fff',
			borderWidth: 1
		}
	}]
}
//////////////////////收费站收费量 end
