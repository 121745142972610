export default {
	"type": "FeatureCollection",
	"features": [{
		"type": "Feature",
		"properties": {
			"adcode": 140100,
			"name": "太原市",
			"center": [112.549248, 37.857014],
			"centroid": [112.322147, 37.960573],
			"childrenNum": 10,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 0,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[113.066848, 38.05647],
						[113.04142, 38.081606],
						[113.012129, 38.084915],
						[112.970905, 38.112124],
						[112.961358, 38.139935],
						[112.94105, 38.13675],
						[112.919874, 38.157143],
						[112.93901, 38.164062],
						[112.936407, 38.178632],
						[112.958668, 38.206601],
						[112.957887, 38.21737],
						[113.00076, 38.248565],
						[113.026319, 38.255475],
						[113.035388, 38.270822],
						[113.051877, 38.275652],
						[113.076699, 38.307067],
						[113.108984, 38.307006],
						[113.150294, 38.328206],
						[113.15663, 38.345247],
						[113.142397, 38.360575],
						[113.151683, 38.375778],
						[113.141572, 38.394334],
						[113.145695, 38.411664],
						[113.121351, 38.421305],
						[113.085551, 38.415753],
						[113.083902, 38.399216],
						[113.069365, 38.388108],
						[113.039293, 38.381699],
						[113.031135, 38.367597],
						[113.00345, 38.343354],
						[112.978238, 38.342255],
						[112.972207, 38.357461],
						[112.945129, 38.333887],
						[112.911021, 38.321181],
						[112.850834, 38.306089],
						[112.822628, 38.287327],
						[112.796115, 38.251011],
						[112.748989, 38.242021],
						[112.693879, 38.241471],
						[112.681686, 38.235844],
						[112.639984, 38.246425],
						[112.648359, 38.227586],
						[112.620761, 38.20654],
						[112.569036, 38.21064],
						[112.557536, 38.223426],
						[112.562526, 38.239147],
						[112.524947, 38.267826],
						[112.524731, 38.249972],
						[112.499606, 38.237312],
						[112.458685, 38.249972],
						[112.446926, 38.27608],
						[112.433126, 38.280664],
						[112.406483, 38.266787],
						[112.36374, 38.278036],
						[112.340611, 38.303034],
						[112.319739, 38.310977],
						[112.305202, 38.3067],
						[112.274002, 38.31391],
						[112.248009, 38.291728],
						[112.269966, 38.267888],
						[112.234036, 38.26159],
						[112.220888, 38.250583],
						[112.25148, 38.219572],
						[112.267753, 38.182488],
						[112.25148, 38.150101],
						[112.236206, 38.142752],
						[112.212166, 38.143671],
						[112.214726, 38.132401],
						[112.160353, 38.15004],
						[112.134751, 38.140976],
						[112.101381, 38.167368],
						[112.046445, 38.171837],
						[112.01173, 38.184202],
						[111.951326, 38.167919],
						[111.915136, 38.180713],
						[111.898125, 38.198034],
						[111.87287, 38.193383],
						[111.869138, 38.220062],
						[111.847658, 38.229788],
						[111.849611, 38.214984],
						[111.81763, 38.214311],
						[111.806434, 38.195464],
						[111.773368, 38.187752],
						[111.766338, 38.179305],
						[111.744164, 38.18359],
						[111.716305, 38.174959],
						[111.716609, 38.15849],
						[111.689749, 38.134668],
						[111.643187, 38.116106],
						[111.625005, 38.10183],
						[111.567682, 38.095518],
						[111.547547, 38.085344],
						[111.546419, 38.074924],
						[111.524158, 38.066096],
						[111.515913, 38.051258],
						[111.52802, 38.037705],
						[111.516868, 38.019672],
						[111.538044, 37.992429],
						[111.545595, 37.955966],
						[111.572412, 37.931955],
						[111.580093, 37.902713],
						[111.547113, 37.885261],
						[111.56638, 37.87598],
						[111.570242, 37.852373],
						[111.642536, 37.861534],
						[111.653515, 37.856677],
						[111.683456, 37.86387],
						[111.706151, 37.878992],
						[111.747158, 37.870509],
						[111.746855, 37.855509],
						[111.767901, 37.841427],
						[111.767814, 37.832325],
						[111.808474, 37.817133],
						[111.819843, 37.796091],
						[111.912272, 37.792953],
						[111.921384, 37.757931],
						[111.935053, 37.740752],
						[111.974975, 37.713159],
						[111.996238, 37.722091],
						[112.023316, 37.723939],
						[112.034642, 37.71125],
						[112.075909, 37.717779],
						[112.10277, 37.716178],
						[112.118652, 37.698558],
						[112.175932, 37.674525],
						[112.174109, 37.6355],
						[112.227006, 37.59232],
						[112.233776, 37.570042],
						[112.255689, 37.561709],
						[112.290448, 37.566894],
						[112.294093, 37.548745],
						[112.273741, 37.524046],
						[112.295698, 37.521452],
						[112.313446, 37.495508],
						[112.290361, 37.48729],
						[112.317439, 37.476414],
						[112.33011, 37.458923],
						[112.352371, 37.463188],
						[112.358967, 37.485004],
						[112.390253, 37.484077],
						[112.394853, 37.462879],
						[112.432779, 37.458861],
						[112.442065, 37.478886],
						[112.464153, 37.468813],
						[112.508241, 37.478639],
						[112.50976, 37.507554],
						[112.544692, 37.518364],
						[112.590646, 37.506813],
						[112.593943, 37.520958],
						[112.623755, 37.525899],
						[112.606745, 37.552079],
						[112.609088, 37.57282],
						[112.598153, 37.599231],
						[112.579667, 37.604413],
						[112.579146, 37.633896],
						[112.619502, 37.642961],
						[112.605399, 37.666758],
						[112.6274, 37.684201],
						[112.626879, 37.707615],
						[112.650486, 37.710017],
						[112.65387, 37.730775],
						[112.667626, 37.740198],
						[112.652959, 37.775413],
						[112.684072, 37.798676],
						[112.67509, 37.811781],
						[112.705639, 37.851205],
						[112.725296, 37.85145],
						[112.73762, 37.875181],
						[112.758232, 37.881573],
						[112.783878, 37.870571],
						[112.765696, 37.914878],
						[112.778106, 37.923663],
						[112.768213, 37.939448],
						[112.794726, 37.969411],
						[112.817638, 37.983345],
						[112.825753, 38.004149],
						[112.863419, 37.989667],
						[112.880602, 37.996417],
						[112.898394, 37.991569],
						[112.920742, 37.996663],
						[112.938837, 37.990526],
						[112.967824, 38.018322],
						[112.989174, 38.002308],
						[113.022847, 38.026419],
						[113.008614, 38.039975],
						[113.024279, 38.0504],
						[113.05127, 38.047272],
						[113.066848, 38.05647]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 140200,
			"name": "大同市",
			"center": [113.295259, 40.09031],
			"centroid": [113.72499, 39.904541],
			"childrenNum": 10,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 1,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[113.577289, 39.438122],
						[113.586141, 39.441249],
						[113.599159, 39.424048],
						[113.649496, 39.434634],
						[113.698314, 39.418814],
						[113.739234, 39.420859],
						[113.766529, 39.405217],
						[113.772777, 39.390715],
						[113.791306, 39.378437],
						[113.840602, 39.370913],
						[113.899574, 39.373019],
						[113.898185, 39.345083],
						[113.942186, 39.30876],
						[113.946873, 39.295805],
						[113.967181, 39.281521],
						[113.958589, 39.222908],
						[113.940104, 39.20692],
						[113.952514, 39.127468],
						[113.961757, 39.100823],
						[113.984365, 39.095384],
						[114.000464, 39.100763],
						[114.006496, 39.122877],
						[114.050801, 39.135864],
						[114.065251, 39.093511],
						[114.096798, 39.083719],
						[114.108732, 39.052281],
						[114.126653, 39.05089],
						[114.157202, 39.06117],
						[114.180895, 39.049076],
						[114.196994, 39.050406],
						[114.226372, 39.06649],
						[114.300141, 39.079246],
						[114.320797, 39.070481],
						[114.349133, 39.076768],
						[114.369658, 39.107531],
						[114.360762, 39.133992],
						[114.388187, 39.176985],
						[114.417999, 39.171613],
						[114.443601, 39.174148],
						[114.453147, 39.192618],
						[114.465601, 39.188514],
						[114.474844, 39.219228],
						[114.436354, 39.229604],
						[114.415959, 39.242873],
						[114.437656, 39.260421],
						[114.425072, 39.285137],
						[114.438611, 39.319424],
						[114.466643, 39.329664],
						[114.479704, 39.351104],
						[114.469464, 39.355199],
						[114.470896, 39.408767],
						[114.496802, 39.438543],
						[114.502313, 39.477082],
						[114.529868, 39.485196],
						[114.536594, 39.512897],
						[114.557336, 39.531998],
						[114.557336, 39.550674],
						[114.568922, 39.573967],
						[114.556989, 39.58141],
						[114.515374, 39.565023],
						[114.495804, 39.608172],
						[114.474758, 39.613751],
						[114.431668, 39.614051],
						[114.408148, 39.651953],
						[114.407107, 39.690374],
						[114.410231, 39.761705],
						[114.416784, 39.776189],
						[114.390704, 39.818608],
						[114.406716, 39.83344],
						[114.395434, 39.867217],
						[114.285084, 39.859686],
						[114.286819, 39.871042],
						[114.245509, 39.861897],
						[114.221468, 39.86118],
						[114.202071, 39.872297],
						[114.204501, 39.885205],
						[114.227717, 39.8959],
						[114.220644, 39.914298],
						[114.174169, 39.897632],
						[114.102873, 39.912865],
						[114.089942, 39.910177],
						[114.067768, 39.922301],
						[114.047373, 39.91615],
						[114.02828, 39.959318],
						[114.029451, 39.985397],
						[114.02125, 39.991781],
						[113.960455, 40.000908],
						[113.932032, 40.009438],
						[113.914631, 40.005919],
						[113.922486, 40.026613],
						[113.954857, 40.030847],
						[113.975556, 40.051057],
						[113.981024, 40.073227],
						[113.973864, 40.097118],
						[113.979852, 40.112306],
						[114.019948, 40.102479],
						[114.043815, 40.056898],
						[114.091157, 40.075313],
						[114.101224, 40.108733],
						[114.089118, 40.121477],
						[114.068028, 40.179748],
						[114.097753, 40.193549],
						[114.123399, 40.188672],
						[114.123963, 40.178141],
						[114.145095, 40.177368],
						[114.180071, 40.191527],
						[114.235875, 40.198308],
						[114.240171, 40.221976],
						[114.255229, 40.236185],
						[114.293111, 40.230122],
						[114.33516, 40.245399],
						[114.362585, 40.250094],
						[114.406412, 40.246172],
						[114.469941, 40.268041],
						[114.510948, 40.302969],
						[114.526439, 40.323574],
						[114.530605, 40.345121],
						[114.499318, 40.354023],
						[114.470809, 40.349691],
						[114.446552, 40.372891],
						[114.403722, 40.353786],
						[114.383544, 40.354023],
						[114.344446, 40.369569],
						[114.314418, 40.369569],
						[114.28708, 40.423418],
						[114.298709, 40.445585],
						[114.27545, 40.458029],
						[114.267205, 40.474202],
						[114.296409, 40.535957],
						[114.293459, 40.551402],
						[114.274322, 40.552881],
						[114.282957, 40.590796],
						[114.258223, 40.610662],
						[114.236135, 40.606997],
						[114.209318, 40.629754],
						[114.216435, 40.635013],
						[114.200075, 40.662191],
						[114.183239, 40.671701],
						[114.162366, 40.713562],
						[114.139541, 40.739941],
						[114.09324, 40.731917],
						[114.072281, 40.72153],
						[114.064079, 40.707128],
						[114.072715, 40.679142],
						[114.070458, 40.660301],
						[114.041818, 40.608652],
						[114.04811, 40.595882],
						[114.076881, 40.575834],
						[114.080178, 40.547733],
						[114.06191, 40.528855],
						[114.011399, 40.515892],
						[113.970089, 40.522226],
						[113.947784, 40.51678],
						[113.930904, 40.493392],
						[113.890114, 40.466442],
						[113.863427, 40.456488],
						[113.850973, 40.460577],
						[113.832704, 40.487055],
						[113.794821, 40.517964],
						[113.790699, 40.502511],
						[113.763448, 40.473965],
						[113.688767, 40.448193],
						[113.667981, 40.423773],
						[113.647803, 40.415533],
						[113.584145, 40.37123],
						[113.559888, 40.348623],
						[113.500135, 40.334497],
						[113.457652, 40.33046],
						[113.387615, 40.319121],
						[113.315972, 40.32013],
						[113.302737, 40.334675],
						[113.291194, 40.36506],
						[113.273967, 40.390093],
						[113.247757, 40.412983],
						[113.219377, 40.402844],
						[113.170733, 40.395965],
						[113.115362, 40.381137],
						[113.033131, 40.368916],
						[112.989824, 40.357405],
						[112.89297, 40.326423],
						[112.890453, 40.305167],
						[112.85513, 40.218409],
						[112.84502, 40.203899],
						[112.750725, 40.168026],
						[112.73033, 40.168086],
						[112.720783, 40.178558],
						[112.67791, 40.201163],
						[112.624319, 40.236839],
						[112.626098, 40.223047],
						[112.609695, 40.218468],
						[112.603186, 40.202947],
						[112.622063, 40.183377],
						[112.622063, 40.163742],
						[112.589778, 40.16148],
						[112.580448, 40.154398],
						[112.581967, 40.128444],
						[112.595723, 40.114748],
						[112.589648, 40.10105],
						[112.622323, 40.088778],
						[112.610563, 40.079365],
						[112.642631, 40.034484],
						[112.617376, 39.948693],
						[112.596547, 39.92045],
						[112.603881, 39.880604],
						[112.591123, 39.835951],
						[112.600019, 39.816036],
						[112.62037, 39.809397],
						[112.621021, 39.760388],
						[112.648359, 39.74069],
						[112.669232, 39.746199],
						[112.693706, 39.769067],
						[112.734409, 39.753324],
						[112.751593, 39.757933],
						[112.752808, 39.774214],
						[112.820806, 39.776788],
						[112.817508, 39.788816],
						[112.831307, 39.79797],
						[112.861596, 39.799765],
						[112.891451, 39.826084],
						[112.911759, 39.829194],
						[112.93428, 39.841811],
						[112.938707, 39.86112],
						[112.954068, 39.85305],
						[112.966826, 39.868173],
						[112.947082, 39.886878],
						[112.939444, 39.912566],
						[113.023107, 39.912327],
						[113.051921, 39.916449],
						[113.122783, 39.908325],
						[113.168433, 39.916747],
						[113.23552, 39.958303],
						[113.285943, 39.948454],
						[113.305036, 39.934424],
						[113.325214, 39.932632],
						[113.352639, 39.906593],
						[113.381192, 39.906056],
						[113.4396, 39.852154],
						[113.396814, 39.837625],
						[113.373208, 39.834516],
						[113.344395, 39.805807],
						[113.32925, 39.80156],
						[113.339925, 39.776548],
						[113.393169, 39.743684],
						[113.41057, 39.714818],
						[113.386139, 39.682524],
						[113.394167, 39.663524],
						[113.383188, 39.649195],
						[113.429403, 39.605772],
						[113.43192, 39.58177],
						[113.458737, 39.578769],
						[113.474012, 39.554577],
						[113.48512, 39.549834],
						[113.511547, 39.520406],
						[113.50634, 39.503044],
						[113.518534, 39.481109],
						[113.570259, 39.452975],
						[113.577289, 39.438122]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 140300,
			"name": "阳泉市",
			"center": [113.583285, 37.861188],
			"centroid": [113.505474, 38.064652],
			"childrenNum": 5,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 2,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[113.555548, 38.521046],
						[113.517449, 38.519828],
						[113.48486, 38.508556],
						[113.458086, 38.510079],
						[113.435087, 38.520681],
						[113.416688, 38.517878],
						[113.368174, 38.495941],
						[113.333242, 38.485823],
						[113.294492, 38.468022],
						[113.284858, 38.451559],
						[113.208572, 38.42045],
						[113.203018, 38.415386],
						[113.145695, 38.411664],
						[113.141572, 38.394334],
						[113.151683, 38.375778],
						[113.142397, 38.360575],
						[113.15663, 38.345247],
						[113.150294, 38.328206],
						[113.108984, 38.307006],
						[113.076699, 38.307067],
						[113.051877, 38.275652],
						[113.035388, 38.270822],
						[113.026319, 38.255475],
						[113.00076, 38.248565],
						[112.957887, 38.21737],
						[112.958668, 38.206601],
						[112.936407, 38.178632],
						[112.93901, 38.164062],
						[112.919874, 38.157143],
						[112.94105, 38.13675],
						[112.961358, 38.139935],
						[112.970905, 38.112124],
						[113.012129, 38.084915],
						[113.04142, 38.081606],
						[113.066848, 38.05647],
						[113.09718, 38.056225],
						[113.099827, 38.065115],
						[113.132503, 38.082464],
						[113.164224, 38.054876],
						[113.198027, 38.058248],
						[113.211913, 38.082096],
						[113.228924, 38.091964],
						[113.253832, 38.090248],
						[113.266416, 38.071246],
						[113.255307, 38.058187],
						[113.265244, 38.033473],
						[113.284728, 38.028812],
						[113.301695, 37.986966],
						[113.335282, 37.980767],
						[113.341747, 37.962106],
						[113.326082, 37.902836],
						[113.332722, 37.889255],
						[113.369693, 37.867251],
						[113.372557, 37.838537],
						[113.393777, 37.822792],
						[113.416862, 37.787722],
						[113.441944, 37.76538],
						[113.44381, 37.743892],
						[113.480868, 37.70016],
						[113.517318, 37.697326],
						[113.532984, 37.678839],
						[113.588701, 37.67662],
						[113.613826, 37.680811],
						[113.646198, 37.664416],
						[113.670932, 37.66306],
						[113.694669, 37.670765],
						[113.705821, 37.685248],
						[113.759282, 37.683399],
						[113.77243, 37.662936],
						[113.7989, 37.672059],
						[113.836696, 37.675757],
						[113.883301, 37.705397],
						[113.905258, 37.706136],
						[113.979852, 37.675203],
						[113.993, 37.684262],
						[113.996906, 37.704473],
						[114.000811, 37.735333],
						[114.041167, 37.756823],
						[114.043858, 37.777444],
						[114.01882, 37.794861],
						[114.006669, 37.813503],
						[113.982543, 37.812826],
						[113.970175, 37.833986],
						[113.971521, 37.868788],
						[113.95924, 37.906338],
						[113.936545, 37.923171],
						[113.901266, 37.984818],
						[113.872366, 37.990342],
						[113.878354, 38.032369],
						[113.876271, 38.05506],
						[113.855963, 38.065912],
						[113.854748, 38.077131],
						[113.824199, 38.106732],
						[113.810053, 38.112553],
						[113.822507, 38.150468],
						[113.833572, 38.147468],
						[113.825457, 38.169143],
						[113.796861, 38.162837],
						[113.756591, 38.171653],
						[113.731119, 38.168347],
						[113.720618, 38.174653],
						[113.738496, 38.189527],
						[113.715064, 38.19375],
						[113.711722, 38.213699],
						[113.67909, 38.205377],
						[113.6498, 38.229788],
						[113.62615, 38.232541],
						[113.598899, 38.227158],
						[113.570346, 38.237434],
						[113.566267, 38.252357],
						[113.54483, 38.270516],
						[113.546045, 38.293072],
						[113.557284, 38.343476],
						[113.534329, 38.365216],
						[113.525433, 38.382981],
						[113.538017, 38.418071],
						[113.573166, 38.44918],
						[113.583494, 38.465949],
						[113.546566, 38.493015],
						[113.557241, 38.503985],
						[113.555548, 38.521046]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 140400,
			"name": "长治市",
			"center": [113.113556, 36.191112],
			"centroid": [112.921277, 36.478022],
			"childrenNum": 12,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 3,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[111.996412, 36.687148],
						[112.019367, 36.67828],
						[112.022535, 36.654669],
						[112.039111, 36.636863],
						[112.041585, 36.60205],
						[112.087105, 36.580105],
						[112.106068, 36.544703],
						[112.132798, 36.524556],
						[112.161785, 36.512916],
						[112.180965, 36.458759],
						[112.202792, 36.417914],
						[112.212816, 36.409642],
						[112.257165, 36.410456],
						[112.290838, 36.383002],
						[112.330761, 36.365133],
						[112.354887, 36.34312],
						[112.39468, 36.345566],
						[112.443671, 36.342681],
						[112.461029, 36.313634],
						[112.454563, 36.304849],
						[112.471226, 36.287088],
						[112.472224, 36.270829],
						[112.506939, 36.259213],
						[112.540699, 36.255571],
						[112.565521, 36.27265],
						[112.574416, 36.263295],
						[112.560964, 36.224796],
						[112.567213, 36.188416],
						[112.561008, 36.162455],
						[112.570381, 36.132021],
						[112.564783, 36.114472],
						[112.49813, 36.082508],
						[112.490536, 36.068725],
						[112.503337, 36.032586],
						[112.522127, 36.031075],
						[112.532151, 36.038317],
						[112.629917, 36.062304],
						[112.658123, 36.043606],
						[112.687674, 36.005],
						[112.714448, 36.014637],
						[112.739399, 35.994416],
						[112.797026, 35.977529],
						[112.824625, 35.98717],
						[112.873659, 35.97608],
						[112.880472, 35.92691],
						[112.910371, 35.922559],
						[112.967477, 35.929748],
						[113.039684, 35.914802],
						[113.065677, 35.891022],
						[113.122132, 35.887111],
						[113.140704, 35.871463],
						[113.199893, 35.897835],
						[113.204103, 35.888878],
						[113.239555, 35.892158],
						[113.263465, 35.866983],
						[113.2767, 35.86326],
						[113.3217, 35.88566],
						[113.353898, 35.881496],
						[113.377157, 35.863007],
						[113.389698, 35.832899],
						[113.41773, 35.83372],
						[113.445198, 35.864901],
						[113.47501, 35.862187],
						[113.504691, 35.850763],
						[113.526344, 35.853351],
						[113.570606, 35.826396],
						[113.585273, 35.825765],
						[113.606059, 35.823744],
						[113.623807, 35.83151],
						[113.641208, 35.822482],
						[113.661038, 35.837255],
						[113.658218, 35.853919],
						[113.644028, 35.854866],
						[113.637389, 35.869886],
						[113.654443, 35.917199],
						[113.647803, 35.96713],
						[113.636868, 35.97589],
						[113.648888, 35.994164],
						[113.686467, 35.986414],
						[113.695927, 35.999897],
						[113.680739, 36.014826],
						[113.695146, 36.026793],
						[113.670195, 36.029501],
						[113.661429, 36.042913],
						[113.685773, 36.056009],
						[113.673796, 36.111578],
						[113.655527, 36.125102],
						[113.693193, 36.124222],
						[113.713762, 36.132964],
						[113.706862, 36.147365],
						[113.681347, 36.150698],
						[113.679264, 36.15994],
						[113.651665, 36.172199],
						[113.697923, 36.181879],
						[113.68638, 36.195517],
						[113.705387, 36.20067],
						[113.698444, 36.214305],
						[113.677311, 36.222535],
						[113.703911, 36.237799],
						[113.70057, 36.252306],
						[113.716235, 36.262604],
						[113.709075, 36.299703],
						[113.723308, 36.304849],
						[113.736413, 36.324677],
						[113.728733, 36.341427],
						[113.729601, 36.381623],
						[113.708381, 36.423365],
						[113.670195, 36.425119],
						[113.629231, 36.454688],
						[113.587226, 36.461014],
						[113.582105, 36.482931],
						[113.55442, 36.494701],
						[113.559454, 36.531001],
						[113.566961, 36.54414],
						[113.58089, 36.541075],
						[113.588137, 36.562719],
						[113.569695, 36.58592],
						[113.539753, 36.59411],
						[113.545264, 36.616865],
						[113.535457, 36.629239],
						[113.486856, 36.635238],
						[113.477049, 36.655232],
						[113.502001, 36.681528],
						[113.507034, 36.70488],
						[113.477526, 36.697263],
						[113.465376, 36.707752],
						[113.4777, 36.726416],
						[113.499353, 36.740583],
						[113.536195, 36.732345],
						[113.543528, 36.744327],
						[113.521875, 36.754435],
						[113.510462, 36.769781],
						[113.495318, 36.807199],
						[113.461775, 36.826151],
						[113.418034, 36.859304],
						[113.399027, 36.863042],
						[113.378328, 36.878928],
						[113.360971, 36.878616],
						[113.372904, 36.90714],
						[113.331159, 36.947602],
						[113.311936, 36.976535],
						[113.294188, 36.977406],
						[113.280215, 36.955754],
						[113.254439, 36.947602],
						[113.227752, 36.931233],
						[113.214647, 36.946669],
						[113.211089, 36.927872],
						[113.1808, 36.930424],
						[113.176287, 36.949905],
						[113.153983, 36.950589],
						[113.156109, 36.921958],
						[113.109287, 36.927249],
						[113.089717, 36.918783],
						[113.067803, 36.928494],
						[113.040248, 36.926004],
						[113.008831, 36.930486],
						[112.967954, 36.90633],
						[112.928596, 36.874381],
						[112.897613, 36.875004],
						[112.877955, 36.894],
						[112.881904, 36.936648],
						[112.87201, 36.947727],
						[112.879604, 36.970127],
						[112.871056, 36.984622],
						[112.853308, 36.987234],
						[112.833086, 37.006328],
						[112.794336, 37.007261],
						[112.75897, 37.04941],
						[112.738792, 37.049659],
						[112.739616, 37.071097],
						[112.716835, 37.095387],
						[112.68251, 37.10017],
						[112.651397, 37.120973],
						[112.637467, 37.106753],
						[112.614425, 37.103089],
						[112.59377, 37.079733],
						[112.541047, 37.09135],
						[112.521389, 37.12029],
						[112.49813, 37.125195],
						[112.482248, 37.118862],
						[112.446448, 37.116875],
						[112.45222, 37.104641],
						[112.485633, 37.094828],
						[112.486153, 37.049472],
						[112.504943, 37.034057],
						[112.513188, 36.994698],
						[112.53866, 36.992024],
						[112.551027, 36.973985],
						[112.542782, 36.960234],
						[112.510454, 36.951025],
						[112.482422, 36.961977],
						[112.448488, 36.939076],
						[112.422452, 36.938267],
						[112.405007, 36.949282],
						[112.354887, 36.95563],
						[112.331889, 36.934096],
						[112.318957, 36.950465],
						[112.279035, 36.978712],
						[112.24866, 36.983938],
						[112.215854, 37.017147],
						[112.176366, 37.012422],
						[112.164172, 36.998741],
						[112.100817, 36.977344],
						[112.061372, 36.975478],
						[112.062761, 36.962412],
						[112.086107, 36.9392],
						[112.068402, 36.908323],
						[112.084024, 36.894063],
						[112.07656, 36.883599],
						[112.051348, 36.880423],
						[112.02887, 36.886963],
						[112.007651, 36.819294],
						[111.988297, 36.810628],
						[111.98552, 36.770592],
						[112.007781, 36.746573],
						[111.997497, 36.730723],
						[111.996412, 36.687148]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 140500,
			"name": "晋城市",
			"center": [112.851274, 35.497553],
			"centroid": [112.713186, 35.610944],
			"childrenNum": 6,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 4,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[112.058812, 35.279909],
						[112.093918, 35.27921],
						[112.217243, 35.253269],
						[112.216678, 35.240232],
						[112.242715, 35.234826],
						[112.282811, 35.242013],
						[112.304421, 35.25187],
						[112.288538, 35.217524],
						[112.367298, 35.220196],
						[112.390731, 35.238833],
						[112.42397, 35.23807],
						[112.457731, 35.226366],
						[112.489799, 35.229928],
						[112.513492, 35.218542],
						[112.540526, 35.222931],
						[112.567864, 35.211734],
						[112.575024, 35.22115],
						[112.618982, 35.220068],
						[112.637207, 35.225666],
						[112.618331, 35.254032],
						[112.636686, 35.264206],
						[112.63916, 35.247864],
						[112.65144, 35.259246],
						[112.654998, 35.244239],
						[112.682684, 35.233745],
						[112.707418, 35.216951],
						[112.703079, 35.201554],
						[112.712669, 35.186981],
						[112.720306, 35.206453],
						[112.735364, 35.210398],
						[112.767258, 35.204099],
						[112.798241, 35.238642],
						[112.822194, 35.258102],
						[112.86906, 35.243794],
						[112.907073, 35.245829],
						[112.929637, 35.256894],
						[112.93606, 35.284422],
						[112.964699, 35.29313],
						[112.982795, 35.287283],
						[112.99156, 35.302346],
						[112.985311, 35.338118],
						[112.999545, 35.362316],
						[113.0202, 35.356029],
						[113.038252, 35.360602],
						[113.067109, 35.353552],
						[113.084076, 35.340531],
						[113.107595, 35.340785],
						[113.126558, 35.33221],
						[113.148906, 35.351202],
						[113.154634, 35.380858],
						[113.169735, 35.386571],
						[113.169518, 35.410883],
						[113.185226, 35.409042],
						[113.190043, 35.44908],
						[113.230052, 35.453267],
						[113.265375, 35.434615],
						[113.304385, 35.427001],
						[113.297356, 35.438549],
						[113.306425, 35.460815],
						[113.294665, 35.467284],
						[113.312327, 35.481236],
						[113.325909, 35.46925],
						[113.34817, 35.468362],
						[113.370778, 35.480094],
						[113.39178, 35.50685],
						[113.416645, 35.516168],
						[113.43921, 35.507547],
						[113.485424, 35.520731],
						[113.5066, 35.516485],
						[113.498529, 35.532392],
						[113.50673, 35.565971],
						[113.541966, 35.596181],
						[113.556677, 35.619795],
						[113.547998, 35.657577],
						[113.560061, 35.660425],
						[113.578547, 35.633531],
						[113.624848, 35.632012],
						[113.624978, 35.652389],
						[113.614911, 35.683008],
						[113.593127, 35.69142],
						[113.60198, 35.706408],
						[113.586792, 35.744022],
						[113.599116, 35.775112],
						[113.583494, 35.794253],
						[113.604974, 35.800127],
						[113.586141, 35.806127],
						[113.585273, 35.825765],
						[113.570606, 35.826396],
						[113.526344, 35.853351],
						[113.504691, 35.850763],
						[113.47501, 35.862187],
						[113.445198, 35.864901],
						[113.41773, 35.83372],
						[113.389698, 35.832899],
						[113.377157, 35.863007],
						[113.353898, 35.881496],
						[113.3217, 35.88566],
						[113.2767, 35.86326],
						[113.263465, 35.866983],
						[113.239555, 35.892158],
						[113.204103, 35.888878],
						[113.199893, 35.897835],
						[113.140704, 35.871463],
						[113.122132, 35.887111],
						[113.065677, 35.891022],
						[113.039684, 35.914802],
						[112.967477, 35.929748],
						[112.910371, 35.922559],
						[112.880472, 35.92691],
						[112.873659, 35.97608],
						[112.824625, 35.98717],
						[112.797026, 35.977529],
						[112.739399, 35.994416],
						[112.714448, 36.014637],
						[112.687674, 36.005],
						[112.658123, 36.043606],
						[112.629917, 36.062304],
						[112.532151, 36.038317],
						[112.522127, 36.031075],
						[112.532324, 35.984902],
						[112.516963, 35.962719],
						[112.463328, 35.940719],
						[112.428527, 35.921424],
						[112.411994, 35.905531],
						[112.381878, 35.895817],
						[112.34738, 35.900863],
						[112.327332, 35.894997],
						[112.278124, 35.903828],
						[112.240371, 35.896952],
						[112.191033, 35.917136],
						[112.163131, 35.907108],
						[112.144168, 35.910387],
						[112.159312, 35.86976],
						[112.183439, 35.873546],
						[112.209779, 35.847986],
						[112.213511, 35.829995],
						[112.186737, 35.817304],
						[112.129544, 35.820082],
						[112.065885, 35.845588],
						[112.04536, 35.864206],
						[112.035596, 35.855939],
						[112.030736, 35.810989],
						[112.048484, 35.790337],
						[112.053735, 35.773027],
						[112.025139, 35.752112],
						[112.037028, 35.722973],
						[112.027395, 35.701096],
						[111.989339, 35.670484],
						[111.964431, 35.664853],
						[111.957575, 35.640113],
						[111.94217, 35.626948],
						[111.948375, 35.576422],
						[111.966731, 35.566668],
						[111.965082, 35.525484],
						[111.946987, 35.506533],
						[111.9712, 35.456882],
						[111.974064, 35.431443],
						[112.00253, 35.401997],
						[112.032776, 35.39019],
						[112.022144, 35.353997],
						[112.052824, 35.341421],
						[112.069096, 35.315691],
						[112.058812, 35.279909]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 140600,
			"name": "朔州市",
			"center": [112.433387, 39.331261],
			"centroid": [112.648197, 39.619375],
			"childrenNum": 6,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 5,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[112.624319, 40.236839],
						[112.564175, 40.257939],
						[112.511539, 40.26917],
						[112.456168, 40.300059],
						[112.418156, 40.295249],
						[112.345558, 40.256453],
						[112.310322, 40.256394],
						[112.290535, 40.223403],
						[112.299994, 40.211868],
						[112.285501, 40.198189],
						[112.232778, 40.169633],
						[112.223274, 40.128801],
						[112.209909, 40.10772],
						[112.183916, 40.084787],
						[112.181833, 40.061487],
						[112.142388, 40.027329],
						[112.13358, 40.001684],
						[112.094786, 39.955498],
						[112.077081, 39.920868],
						[112.042539, 39.886161],
						[112.035119, 39.854366],
						[112.005872, 39.820821],
						[111.97619, 39.79809],
						[111.967468, 39.782652],
						[111.970896, 39.766014],
						[111.964518, 39.72524],
						[111.968857, 39.710385],
						[111.95636, 39.687737],
						[111.923944, 39.665742],
						[111.937874, 39.644638],
						[111.93028, 39.611411],
						[111.942734, 39.585311],
						[111.971721, 39.567064],
						[112.002227, 39.537163],
						[111.987516, 39.526112],
						[111.943819, 39.525511],
						[111.906023, 39.517582],
						[111.895782, 39.497395],
						[111.914875, 39.463256],
						[111.951543, 39.46007],
						[111.971287, 39.446481],
						[111.987299, 39.448405],
						[111.999753, 39.413219],
						[112.012251, 39.40281],
						[112.007174, 39.383011],
						[112.023186, 39.368866],
						[112.019671, 39.357065],
						[112.064193, 39.304904],
						[112.048528, 39.29707],
						[112.068055, 39.282304],
						[112.085412, 39.253125],
						[112.100644, 39.255778],
						[112.107977, 39.270128],
						[112.134795, 39.27917],
						[112.182831, 39.266631],
						[112.194374, 39.249688],
						[112.192248, 39.229966],
						[112.201881, 39.211385],
						[112.219716, 39.203601],
						[112.218675, 39.175657],
						[112.250265, 39.18272],
						[112.2635, 39.175476],
						[112.274088, 39.126985],
						[112.298866, 39.129703],
						[112.304681, 39.119131],
						[112.289537, 39.112183],
						[112.294397, 39.099071],
						[112.314054, 39.100702],
						[112.312579, 39.115446],
						[112.331455, 39.12475],
						[112.365345, 39.108075],
						[112.405137, 39.114661],
						[112.437726, 39.104389],
						[112.457514, 39.112063],
						[112.505377, 39.140455],
						[112.546167, 39.138099],
						[112.557797, 39.11889],
						[112.577584, 39.113513],
						[112.589344, 39.088434],
						[112.638292, 39.08523],
						[112.649965, 39.089764],
						[112.655302, 39.119011],
						[112.672399, 39.13224],
						[112.686502, 39.126381],
						[112.725947, 39.140032],
						[112.747254, 39.158873],
						[112.751029, 39.183384],
						[112.780927, 39.192679],
						[112.807701, 39.173303],
						[112.819764, 39.174933],
						[112.833694, 39.206256],
						[112.872922, 39.212532],
						[112.88633, 39.238953],
						[112.898264, 39.246552],
						[112.921089, 39.287126],
						[112.967346, 39.297251],
						[113.035995, 39.321171],
						[113.054264, 39.349659],
						[113.088632, 39.36128],
						[113.10894, 39.361882],
						[113.135888, 39.343818],
						[113.130073, 39.325388],
						[113.164701, 39.307073],
						[113.212174, 39.295322],
						[113.225105, 39.296407],
						[113.258649, 39.314725],
						[113.362403, 39.314303],
						[113.380628, 39.30171],
						[113.422677, 39.332194],
						[113.449234, 39.321653],
						[113.452879, 39.33912],
						[113.515062, 39.346949],
						[113.533895, 39.384877],
						[113.561103, 39.404315],
						[113.560842, 39.426815],
						[113.577289, 39.438122],
						[113.570259, 39.452975],
						[113.518534, 39.481109],
						[113.50634, 39.503044],
						[113.511547, 39.520406],
						[113.48512, 39.549834],
						[113.474012, 39.554577],
						[113.458737, 39.578769],
						[113.43192, 39.58177],
						[113.429403, 39.605772],
						[113.383188, 39.649195],
						[113.394167, 39.663524],
						[113.386139, 39.682524],
						[113.41057, 39.714818],
						[113.393169, 39.743684],
						[113.339925, 39.776548],
						[113.32925, 39.80156],
						[113.344395, 39.805807],
						[113.373208, 39.834516],
						[113.396814, 39.837625],
						[113.4396, 39.852154],
						[113.381192, 39.906056],
						[113.352639, 39.906593],
						[113.325214, 39.932632],
						[113.305036, 39.934424],
						[113.285943, 39.948454],
						[113.23552, 39.958303],
						[113.168433, 39.916747],
						[113.122783, 39.908325],
						[113.051921, 39.916449],
						[113.023107, 39.912327],
						[112.939444, 39.912566],
						[112.947082, 39.886878],
						[112.966826, 39.868173],
						[112.954068, 39.85305],
						[112.938707, 39.86112],
						[112.93428, 39.841811],
						[112.911759, 39.829194],
						[112.891451, 39.826084],
						[112.861596, 39.799765],
						[112.831307, 39.79797],
						[112.817508, 39.788816],
						[112.820806, 39.776788],
						[112.752808, 39.774214],
						[112.751593, 39.757933],
						[112.734409, 39.753324],
						[112.693706, 39.769067],
						[112.669232, 39.746199],
						[112.648359, 39.74069],
						[112.621021, 39.760388],
						[112.62037, 39.809397],
						[112.600019, 39.816036],
						[112.591123, 39.835951],
						[112.603881, 39.880604],
						[112.596547, 39.92045],
						[112.617376, 39.948693],
						[112.642631, 40.034484],
						[112.610563, 40.079365],
						[112.622323, 40.088778],
						[112.589648, 40.10105],
						[112.595723, 40.114748],
						[112.581967, 40.128444],
						[112.580448, 40.154398],
						[112.589778, 40.16148],
						[112.622063, 40.163742],
						[112.622063, 40.183377],
						[112.603186, 40.202947],
						[112.609695, 40.218468],
						[112.626098, 40.223047],
						[112.624319, 40.236839]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 140700,
			"name": "晋中市",
			"center": [112.736465, 37.696495],
			"centroid": [112.969398, 37.329161],
			"childrenNum": 11,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 6,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[113.543528, 36.744327],
						[113.569174, 36.758116],
						[113.59994, 36.752875],
						[113.655744, 36.785686],
						[113.680956, 36.790114],
						[113.673883, 36.807511],
						[113.684124, 36.824779],
						[113.67627, 36.855628],
						[113.696925, 36.882291],
						[113.710507, 36.887336],
						[113.731597, 36.878616],
						[113.731944, 36.859117],
						[113.742055, 36.851142],
						[113.772343, 36.871141],
						[113.786924, 36.870082],
						[113.792825, 36.894748],
						[113.761669, 36.956065],
						[113.777464, 36.968572],
						[113.794952, 36.995009],
						[113.771866, 37.016836],
						[113.790178, 37.042946],
						[113.788225, 37.059788],
						[113.768785, 37.062523],
						[113.758154, 37.075633],
						[113.773515, 37.106939],
						[113.767353, 37.144626],
						[113.773168, 37.151888],
						[113.831923, 37.167527],
						[113.836566, 37.189491],
						[113.853099, 37.215107],
						[113.886425, 37.239165],
						[113.886859, 37.25993],
						[113.89901, 37.279512],
						[113.902177, 37.309991],
						[113.921184, 37.330675],
						[113.959848, 37.349001],
						[113.973864, 37.403147],
						[113.995344, 37.421146],
						[114.014524, 37.424609],
						[114.025329, 37.441304],
						[114.028323, 37.47456],
						[114.036785, 37.49421],
						[114.05961, 37.515893],
						[114.123268, 37.601452],
						[114.115371, 37.619896],
						[114.131123, 37.648264],
						[114.139715, 37.67588],
						[114.128259, 37.698188],
						[114.08825, 37.708785],
						[114.068028, 37.721537],
						[114.047243, 37.720243],
						[113.996906, 37.704473],
						[113.993, 37.684262],
						[113.979852, 37.675203],
						[113.905258, 37.706136],
						[113.883301, 37.705397],
						[113.836696, 37.675757],
						[113.7989, 37.672059],
						[113.77243, 37.662936],
						[113.759282, 37.683399],
						[113.705821, 37.685248],
						[113.694669, 37.670765],
						[113.670932, 37.66306],
						[113.646198, 37.664416],
						[113.613826, 37.680811],
						[113.588701, 37.67662],
						[113.532984, 37.678839],
						[113.517318, 37.697326],
						[113.480868, 37.70016],
						[113.44381, 37.743892],
						[113.441944, 37.76538],
						[113.416862, 37.787722],
						[113.393777, 37.822792],
						[113.372557, 37.838537],
						[113.369693, 37.867251],
						[113.332722, 37.889255],
						[113.326082, 37.902836],
						[113.341747, 37.962106],
						[113.335282, 37.980767],
						[113.301695, 37.986966],
						[113.284728, 38.028812],
						[113.265244, 38.033473],
						[113.255307, 38.058187],
						[113.266416, 38.071246],
						[113.253832, 38.090248],
						[113.228924, 38.091964],
						[113.211913, 38.082096],
						[113.198027, 38.058248],
						[113.164224, 38.054876],
						[113.132503, 38.082464],
						[113.099827, 38.065115],
						[113.09718, 38.056225],
						[113.066848, 38.05647],
						[113.05127, 38.047272],
						[113.024279, 38.0504],
						[113.008614, 38.039975],
						[113.022847, 38.026419],
						[112.989174, 38.002308],
						[112.967824, 38.018322],
						[112.938837, 37.990526],
						[112.920742, 37.996663],
						[112.898394, 37.991569],
						[112.880602, 37.996417],
						[112.863419, 37.989667],
						[112.825753, 38.004149],
						[112.817638, 37.983345],
						[112.794726, 37.969411],
						[112.768213, 37.939448],
						[112.778106, 37.923663],
						[112.765696, 37.914878],
						[112.783878, 37.870571],
						[112.758232, 37.881573],
						[112.73762, 37.875181],
						[112.725296, 37.85145],
						[112.705639, 37.851205],
						[112.67509, 37.811781],
						[112.684072, 37.798676],
						[112.652959, 37.775413],
						[112.667626, 37.740198],
						[112.65387, 37.730775],
						[112.650486, 37.710017],
						[112.626879, 37.707615],
						[112.6274, 37.684201],
						[112.605399, 37.666758],
						[112.619502, 37.642961],
						[112.579146, 37.633896],
						[112.579667, 37.604413],
						[112.598153, 37.599231],
						[112.609088, 37.57282],
						[112.606745, 37.552079],
						[112.623755, 37.525899],
						[112.593943, 37.520958],
						[112.590646, 37.506813],
						[112.544692, 37.518364],
						[112.50976, 37.507554],
						[112.508241, 37.478639],
						[112.464153, 37.468813],
						[112.442065, 37.478886],
						[112.432779, 37.458861],
						[112.394853, 37.462879],
						[112.390253, 37.484077],
						[112.358967, 37.485004],
						[112.352371, 37.463188],
						[112.33011, 37.458923],
						[112.327246, 37.448352],
						[112.286933, 37.437533],
						[112.263847, 37.421331],
						[112.259638, 37.394115],
						[112.217243, 37.338229],
						[112.177364, 37.308071],
						[112.139611, 37.266499],
						[112.12169, 37.274493],
						[112.117611, 37.296116],
						[112.102596, 37.298718],
						[112.095002, 37.315441],
						[112.06927, 37.317485],
						[112.049699, 37.298594],
						[112.04549, 37.280008],
						[112.014811, 37.268668],
						[111.980443, 37.271271],
						[111.972328, 37.254042],
						[111.942083, 37.255406],
						[111.956577, 37.240281],
						[111.958876, 37.222238],
						[111.946422, 37.209402],
						[111.951239, 37.179441],
						[111.934272, 37.148971],
						[111.923337, 37.106256],
						[111.906457, 37.098493],
						[111.88003, 37.057178],
						[111.845836, 37.034182],
						[111.826395, 37.044314],
						[111.791637, 37.022556],
						[111.759482, 37.015468],
						[111.735355, 36.997559],
						[111.680679, 36.988043],
						[111.679334, 36.970252],
						[111.647483, 36.964528],
						[111.637286, 36.943183],
						[111.580353, 36.967016],
						[111.552798, 36.982258],
						[111.541038, 36.977655],
						[111.527152, 36.999052],
						[111.498643, 36.991277],
						[111.512312, 36.973487],
						[111.540127, 36.954448],
						[111.541429, 36.931856],
						[111.570286, 36.928868],
						[111.584736, 36.918783],
						[111.543338, 36.892506],
						[111.505108, 36.886527],
						[111.487838, 36.870331],
						[111.464752, 36.865285],
						[111.455379, 36.8484],
						[111.420577, 36.844287],
						[111.414112, 36.832944],
						[111.414676, 36.804019],
						[111.434767, 36.785748],
						[111.461932, 36.771902],
						[111.490354, 36.7792],
						[111.49912, 36.803957],
						[111.515306, 36.779013],
						[111.518951, 36.789303],
						[111.54234, 36.788243],
						[111.551583, 36.778763],
						[111.538218, 36.768347],
						[111.546462, 36.745512],
						[111.572195, 36.716492],
						[111.622966, 36.687772],
						[111.658331, 36.656918],
						[111.676861, 36.651483],
						[111.68567, 36.661728],
						[111.672217, 36.69539],
						[111.718258, 36.711311],
						[111.759526, 36.717303],
						[111.786647, 36.703195],
						[111.818498, 36.712809],
						[111.842711, 36.711685],
						[111.837157, 36.696514],
						[111.874259, 36.70563],
						[111.936138, 36.704943],
						[111.981832, 36.677031],
						[111.996412, 36.687148],
						[111.997497, 36.730723],
						[112.007781, 36.746573],
						[111.98552, 36.770592],
						[111.988297, 36.810628],
						[112.007651, 36.819294],
						[112.02887, 36.886963],
						[112.051348, 36.880423],
						[112.07656, 36.883599],
						[112.084024, 36.894063],
						[112.068402, 36.908323],
						[112.086107, 36.9392],
						[112.062761, 36.962412],
						[112.061372, 36.975478],
						[112.100817, 36.977344],
						[112.164172, 36.998741],
						[112.176366, 37.012422],
						[112.215854, 37.017147],
						[112.24866, 36.983938],
						[112.279035, 36.978712],
						[112.318957, 36.950465],
						[112.331889, 36.934096],
						[112.354887, 36.95563],
						[112.405007, 36.949282],
						[112.422452, 36.938267],
						[112.448488, 36.939076],
						[112.482422, 36.961977],
						[112.510454, 36.951025],
						[112.542782, 36.960234],
						[112.551027, 36.973985],
						[112.53866, 36.992024],
						[112.513188, 36.994698],
						[112.504943, 37.034057],
						[112.486153, 37.049472],
						[112.485633, 37.094828],
						[112.45222, 37.104641],
						[112.446448, 37.116875],
						[112.482248, 37.118862],
						[112.49813, 37.125195],
						[112.521389, 37.12029],
						[112.541047, 37.09135],
						[112.59377, 37.079733],
						[112.614425, 37.103089],
						[112.637467, 37.106753],
						[112.651397, 37.120973],
						[112.68251, 37.10017],
						[112.716835, 37.095387],
						[112.739616, 37.071097],
						[112.738792, 37.049659],
						[112.75897, 37.04941],
						[112.794336, 37.007261],
						[112.833086, 37.006328],
						[112.853308, 36.987234],
						[112.871056, 36.984622],
						[112.879604, 36.970127],
						[112.87201, 36.947727],
						[112.881904, 36.936648],
						[112.877955, 36.894],
						[112.897613, 36.875004],
						[112.928596, 36.874381],
						[112.967954, 36.90633],
						[113.008831, 36.930486],
						[113.040248, 36.926004],
						[113.067803, 36.928494],
						[113.089717, 36.918783],
						[113.109287, 36.927249],
						[113.156109, 36.921958],
						[113.153983, 36.950589],
						[113.176287, 36.949905],
						[113.1808, 36.930424],
						[113.211089, 36.927872],
						[113.214647, 36.946669],
						[113.227752, 36.931233],
						[113.254439, 36.947602],
						[113.280215, 36.955754],
						[113.294188, 36.977406],
						[113.311936, 36.976535],
						[113.331159, 36.947602],
						[113.372904, 36.90714],
						[113.360971, 36.878616],
						[113.378328, 36.878928],
						[113.399027, 36.863042],
						[113.418034, 36.859304],
						[113.461775, 36.826151],
						[113.495318, 36.807199],
						[113.510462, 36.769781],
						[113.521875, 36.754435],
						[113.543528, 36.744327]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 140800,
			"name": "运城市",
			"center": [111.003957, 35.022778],
			"centroid": [111.070718, 35.188073],
			"childrenNum": 13,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 7,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[110.57722, 35.73688],
						[110.577611, 35.701476],
						[110.592278, 35.682313],
						[110.592365, 35.663272],
						[110.604385, 35.657514],
						[110.609332, 35.632138],
						[110.589067, 35.602132],
						[110.580909, 35.563121],
						[110.5675, 35.539489],
						[110.531657, 35.511477],
						[110.513084, 35.483138],
						[110.48592, 35.422877],
						[110.477588, 35.413865],
						[110.450033, 35.327763],
						[110.403993, 35.28652],
						[110.374832, 35.251934],
						[110.378347, 35.210844],
						[110.364331, 35.197991],
						[110.364721, 35.140255],
						[110.373704, 35.134205],
						[110.346453, 35.084326],
						[110.333131, 35.045063],
						[110.32059, 35.038496],
						[110.32085, 35.005144],
						[110.30154, 34.988622],
						[110.274679, 34.947271],
						[110.262182, 34.944335],
						[110.254328, 34.919182],
						[110.230982, 34.880734],
						[110.238836, 34.852047],
						[110.233716, 34.837412],
						[110.246864, 34.788886],
						[110.243175, 34.725805],
						[110.229593, 34.692646],
						[110.236623, 34.670426],
						[110.269082, 34.629491],
						[110.295508, 34.611036],
						[110.350792, 34.606613],
						[110.407724, 34.589242],
						[110.48848, 34.610779],
						[110.533176, 34.583472],
						[110.547365, 34.583601],
						[110.610981, 34.607318],
						[110.65355, 34.608023],
						[110.706403, 34.603985],
						[110.749146, 34.652235],
						[110.791846, 34.649737],
						[110.824304, 34.615842],
						[110.853161, 34.635322],
						[110.88371, 34.644099],
						[110.903715, 34.668825],
						[110.91977, 34.730221],
						[110.935522, 34.730541],
						[110.964032, 34.706411],
						[110.976312, 34.706475],
						[111.035371, 34.740908],
						[111.066398, 34.751594],
						[111.11821, 34.756584],
						[111.148499, 34.807494],
						[111.160736, 34.814591],
						[111.177789, 34.803978],
						[111.209423, 34.800972],
						[111.223483, 34.789462],
						[111.239842, 34.793555],
						[111.255247, 34.819578],
						[111.294128, 34.807686],
						[111.340299, 34.83134],
						[111.355357, 34.82303],
						[111.395583, 34.814975],
						[111.439367, 34.838179],
						[111.464622, 34.826929],
						[111.502635, 34.829933],
						[111.527803, 34.85013],
						[111.554447, 34.852239],
						[111.570806, 34.843292],
						[111.589422, 34.861887],
						[111.591852, 34.881629],
						[111.617672, 34.894787],
						[111.621577, 34.916308],
						[111.647006, 34.938654],
						[111.681547, 34.95225],
						[111.669614, 34.988176],
						[111.687102, 34.988495],
						[111.711749, 35.003613],
						[111.739348, 35.004251],
						[111.749111, 35.016433],
						[111.768595, 35.015349],
						[111.781353, 35.030016],
						[111.809515, 35.036839],
						[111.810426, 35.063295],
						[111.818411, 35.068457],
						[111.868748, 35.075723],
						[111.885584, 35.069987],
						[111.900078, 35.079865],
						[111.91839, 35.071516],
						[111.928371, 35.082032],
						[111.963129, 35.079291],
						[111.977926, 35.067183],
						[112.02119, 35.068011],
						[112.038938, 35.045509],
						[112.062153, 35.055773],
						[112.056252, 35.098471],
						[112.067231, 35.136243],
						[112.063325, 35.159802],
						[112.041498, 35.189782],
						[112.042062, 35.200281],
						[112.078686, 35.219369],
						[112.079641, 35.242395],
						[112.058812, 35.279909],
						[112.069096, 35.315691],
						[112.052824, 35.341421],
						[112.022144, 35.353997],
						[112.032776, 35.39019],
						[112.00253, 35.401997],
						[111.974064, 35.431443],
						[111.972632, 35.420339],
						[111.94933, 35.393618],
						[111.89179, 35.387905],
						[111.851303, 35.399331],
						[111.848483, 35.424463],
						[111.838155, 35.435123],
						[111.814245, 35.430364],
						[111.838893, 35.458214],
						[111.833989, 35.472485],
						[111.845445, 35.482187],
						[111.853256, 35.511477],
						[111.902768, 35.52333],
						[111.908843, 35.541137],
						[111.84384, 35.553302],
						[111.839934, 35.584592],
						[111.860503, 35.599663],
						[111.823358, 35.599347],
						[111.764646, 35.611376],
						[111.722815, 35.644859],
						[111.683413, 35.639923],
						[111.675559, 35.655996],
						[111.653211, 35.643214],
						[111.612638, 35.649984],
						[111.604089, 35.633151],
						[111.59962, 35.597194],
						[111.586775, 35.580982],
						[111.585213, 35.55875],
						[111.570806, 35.554379],
						[111.557398, 35.567302],
						[111.506584, 35.574776],
						[111.482891, 35.567048],
						[111.423051, 35.55951],
						[111.410336, 35.572179],
						[111.361866, 35.551211],
						[111.296254, 35.536194],
						[111.271303, 35.55894],
						[111.283974, 35.571925],
						[111.266269, 35.598017],
						[111.268135, 35.629923],
						[111.290266, 35.629796],
						[111.302026, 35.650111],
						[111.291741, 35.669535],
						[111.313308, 35.677379],
						[111.347589, 35.670737],
						[111.336784, 35.709633],
						[111.32203, 35.71311],
						[111.291438, 35.704637],
						[111.265184, 35.679783],
						[111.252774, 35.680289],
						[111.23598, 35.703626],
						[111.196015, 35.71937],
						[111.188508, 35.730496],
						[111.183821, 35.764813],
						[111.16642, 35.77606],
						[111.144854, 35.811494],
						[111.126932, 35.819703],
						[111.101113, 35.812442],
						[111.061798, 35.755777],
						[111.057025, 35.741052],
						[111.002652, 35.734099],
						[110.976356, 35.807832],
						[110.951708, 35.786041],
						[110.949408, 35.7499],
						[110.953834, 35.719053],
						[110.91248, 35.690408],
						[110.898681, 35.690851],
						[110.87412, 35.709696],
						[110.84548, 35.719117],
						[110.801826, 35.723226],
						[110.759561, 35.732456],
						[110.746586, 35.744338],
						[110.68475, 35.729105],
						[110.67976, 35.742569],
						[110.696813, 35.765319],
						[110.675637, 35.768415],
						[110.656153, 35.788189],
						[110.62747, 35.784399],
						[110.616752, 35.762476],
						[110.57722, 35.73688]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 140900,
			"name": "忻州市",
			"center": [112.733538, 38.41769],
			"centroid": [112.414867, 38.885126],
			"childrenNum": 14,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 8,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[111.93028, 39.611411],
						[111.902031, 39.61717],
						[111.878902, 39.605892],
						[111.842538, 39.620109],
						[111.787645, 39.589691],
						[111.722684, 39.606012],
						[111.707844, 39.621429],
						[111.646528, 39.644278],
						[111.61663, 39.633305],
						[111.589596, 39.644938],
						[111.568637, 39.643559],
						[111.557615, 39.654231],
						[111.501941, 39.663104],
						[111.461541, 39.646377],
						[111.438108, 39.643019],
						[111.442751, 39.630186],
						[111.46293, 39.624488],
						[111.451951, 39.608112],
						[111.443359, 39.620169],
						[111.428952, 39.613691],
						[111.438803, 39.601452],
						[111.433205, 39.561841],
						[111.422487, 39.542148],
						[111.433986, 39.523409],
						[111.426479, 39.503404],
						[111.38864, 39.492167],
						[111.364165, 39.467284],
						[111.364079, 39.446361],
						[111.352449, 39.426754],
						[111.337218, 39.42092],
						[111.289268, 39.41719],
						[111.26206, 39.424228],
						[111.199356, 39.420799],
						[111.172192, 39.423386],
						[111.145331, 39.409429],
						[111.119598, 39.377233],
						[111.125587, 39.366458],
						[111.155051, 39.368264],
						[111.155398, 39.338699],
						[111.167505, 39.337253],
						[111.183648, 39.350924],
						[111.195017, 39.344962],
						[111.179438, 39.326773],
						[111.187987, 39.314303],
						[111.213806, 39.301288],
						[111.24748, 39.302313],
						[111.227171, 39.284354],
						[111.213546, 39.257165],
						[111.219013, 39.245648],
						[111.163079, 39.152473],
						[111.174144, 39.13514],
						[111.162515, 39.108437],
						[111.147327, 39.100521],
						[111.138345, 39.064314],
						[111.09417, 39.030205],
						[111.038235, 39.020102],
						[110.9984, 38.9985],
						[110.983299, 38.980342],
						[111.009899, 38.932626],
						[111.015714, 38.896211],
						[111.012329, 38.87833],
						[110.996013, 38.868024],
						[111.010116, 38.841584],
						[110.99649, 38.825085],
						[110.979133, 38.787705],
						[110.956308, 38.776717],
						[110.96503, 38.755709],
						[110.945286, 38.729471],
						[110.955006, 38.709482],
						[110.949495, 38.685841],
						[110.985946, 38.684321],
						[111.003086, 38.663225],
						[111.025087, 38.659334],
						[111.071301, 38.686327],
						[111.123634, 38.706505],
						[111.151753, 38.723882],
						[111.202437, 38.72935],
						[111.219664, 38.711791],
						[111.227519, 38.66736],
						[111.248044, 38.657449],
						[111.25004, 38.636041],
						[111.276206, 38.594301],
						[111.346938, 38.60343],
						[111.348934, 38.586815],
						[111.373625, 38.573057],
						[111.432771, 38.570256],
						[111.44631, 38.56362],
						[111.450779, 38.530306],
						[111.468397, 38.510384],
						[111.503633, 38.483873],
						[111.505846, 38.518975],
						[111.522422, 38.524763],
						[111.551757, 38.506849],
						[111.565599, 38.511907],
						[111.609557, 38.542184],
						[111.684368, 38.566603],
						[111.706325, 38.598318],
						[111.719256, 38.602335],
						[111.749762, 38.581884],
						[111.769029, 38.537129],
						[111.725375, 38.514161],
						[111.728759, 38.500024],
						[111.793546, 38.450827],
						[111.79372, 38.429723],
						[111.782828, 38.421],
						[111.793763, 38.402572],
						[111.78886, 38.387803],
						[111.823314, 38.369062],
						[111.821535, 38.336758],
						[111.841149, 38.320997],
						[111.842364, 38.304072],
						[111.777274, 38.292705],
						[111.779183, 38.248015],
						[111.800272, 38.235416],
						[111.81763, 38.214311],
						[111.849611, 38.214984],
						[111.847658, 38.229788],
						[111.869138, 38.220062],
						[111.87287, 38.193383],
						[111.898125, 38.198034],
						[111.915136, 38.180713],
						[111.951326, 38.167919],
						[112.01173, 38.184202],
						[112.046445, 38.171837],
						[112.101381, 38.167368],
						[112.134751, 38.140976],
						[112.160353, 38.15004],
						[112.214726, 38.132401],
						[112.212166, 38.143671],
						[112.236206, 38.142752],
						[112.25148, 38.150101],
						[112.267753, 38.182488],
						[112.25148, 38.219572],
						[112.220888, 38.250583],
						[112.234036, 38.26159],
						[112.269966, 38.267888],
						[112.248009, 38.291728],
						[112.274002, 38.31391],
						[112.305202, 38.3067],
						[112.319739, 38.310977],
						[112.340611, 38.303034],
						[112.36374, 38.278036],
						[112.406483, 38.266787],
						[112.433126, 38.280664],
						[112.446926, 38.27608],
						[112.458685, 38.249972],
						[112.499606, 38.237312],
						[112.524731, 38.249972],
						[112.524947, 38.267826],
						[112.562526, 38.239147],
						[112.557536, 38.223426],
						[112.569036, 38.21064],
						[112.620761, 38.20654],
						[112.648359, 38.227586],
						[112.639984, 38.246425],
						[112.681686, 38.235844],
						[112.693879, 38.241471],
						[112.748989, 38.242021],
						[112.796115, 38.251011],
						[112.822628, 38.287327],
						[112.850834, 38.306089],
						[112.911021, 38.321181],
						[112.945129, 38.333887],
						[112.972207, 38.357461],
						[112.978238, 38.342255],
						[113.00345, 38.343354],
						[113.031135, 38.367597],
						[113.039293, 38.381699],
						[113.069365, 38.388108],
						[113.083902, 38.399216],
						[113.085551, 38.415753],
						[113.121351, 38.421305],
						[113.145695, 38.411664],
						[113.203018, 38.415386],
						[113.208572, 38.42045],
						[113.284858, 38.451559],
						[113.294492, 38.468022],
						[113.333242, 38.485823],
						[113.368174, 38.495941],
						[113.416688, 38.517878],
						[113.435087, 38.520681],
						[113.458086, 38.510079],
						[113.48486, 38.508556],
						[113.517449, 38.519828],
						[113.555548, 38.521046],
						[113.562057, 38.558322],
						[113.602804, 38.586876],
						[113.604236, 38.616087],
						[113.612871, 38.646016],
						[113.632616, 38.65307],
						[113.66707, 38.646989],
						[113.702132, 38.651672],
						[113.713805, 38.663712],
						[113.709987, 38.698301],
						[113.729036, 38.711974],
						[113.745396, 38.701522],
						[113.775641, 38.709847],
						[113.780415, 38.728317],
						[113.802936, 38.7633],
						[113.83956, 38.758381],
						[113.836566, 38.795959],
						[113.853663, 38.810159],
						[113.855529, 38.828967],
						[113.835611, 38.842555],
						[113.80133, 38.854866],
						[113.776205, 38.885665],
						[113.775121, 38.919056],
						[113.767527, 38.959637],
						[113.776726, 38.98694],
						[113.806798, 38.989725],
						[113.830534, 39.011813],
						[113.884429, 39.051676],
						[113.898012, 39.067639],
						[113.930297, 39.063467],
						[113.942881, 39.087467],
						[113.961757, 39.100823],
						[113.952514, 39.127468],
						[113.940104, 39.20692],
						[113.958589, 39.222908],
						[113.967181, 39.281521],
						[113.946873, 39.295805],
						[113.942186, 39.30876],
						[113.898185, 39.345083],
						[113.899574, 39.373019],
						[113.840602, 39.370913],
						[113.791306, 39.378437],
						[113.772777, 39.390715],
						[113.766529, 39.405217],
						[113.739234, 39.420859],
						[113.698314, 39.418814],
						[113.649496, 39.434634],
						[113.599159, 39.424048],
						[113.586141, 39.441249],
						[113.577289, 39.438122],
						[113.560842, 39.426815],
						[113.561103, 39.404315],
						[113.533895, 39.384877],
						[113.515062, 39.346949],
						[113.452879, 39.33912],
						[113.449234, 39.321653],
						[113.422677, 39.332194],
						[113.380628, 39.30171],
						[113.362403, 39.314303],
						[113.258649, 39.314725],
						[113.225105, 39.296407],
						[113.212174, 39.295322],
						[113.164701, 39.307073],
						[113.130073, 39.325388],
						[113.135888, 39.343818],
						[113.10894, 39.361882],
						[113.088632, 39.36128],
						[113.054264, 39.349659],
						[113.035995, 39.321171],
						[112.967346, 39.297251],
						[112.921089, 39.287126],
						[112.898264, 39.246552],
						[112.88633, 39.238953],
						[112.872922, 39.212532],
						[112.833694, 39.206256],
						[112.819764, 39.174933],
						[112.807701, 39.173303],
						[112.780927, 39.192679],
						[112.751029, 39.183384],
						[112.747254, 39.158873],
						[112.725947, 39.140032],
						[112.686502, 39.126381],
						[112.672399, 39.13224],
						[112.655302, 39.119011],
						[112.649965, 39.089764],
						[112.638292, 39.08523],
						[112.589344, 39.088434],
						[112.577584, 39.113513],
						[112.557797, 39.11889],
						[112.546167, 39.138099],
						[112.505377, 39.140455],
						[112.457514, 39.112063],
						[112.437726, 39.104389],
						[112.405137, 39.114661],
						[112.365345, 39.108075],
						[112.331455, 39.12475],
						[112.312579, 39.115446],
						[112.314054, 39.100702],
						[112.294397, 39.099071],
						[112.289537, 39.112183],
						[112.304681, 39.119131],
						[112.298866, 39.129703],
						[112.274088, 39.126985],
						[112.2635, 39.175476],
						[112.250265, 39.18272],
						[112.218675, 39.175657],
						[112.219716, 39.203601],
						[112.201881, 39.211385],
						[112.192248, 39.229966],
						[112.194374, 39.249688],
						[112.182831, 39.266631],
						[112.134795, 39.27917],
						[112.107977, 39.270128],
						[112.100644, 39.255778],
						[112.085412, 39.253125],
						[112.068055, 39.282304],
						[112.048528, 39.29707],
						[112.064193, 39.304904],
						[112.019671, 39.357065],
						[112.023186, 39.368866],
						[112.007174, 39.383011],
						[112.012251, 39.40281],
						[111.999753, 39.413219],
						[111.987299, 39.448405],
						[111.971287, 39.446481],
						[111.951543, 39.46007],
						[111.914875, 39.463256],
						[111.895782, 39.497395],
						[111.906023, 39.517582],
						[111.943819, 39.525511],
						[111.987516, 39.526112],
						[112.002227, 39.537163],
						[111.971721, 39.567064],
						[111.942734, 39.585311],
						[111.93028, 39.611411]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 141000,
			"name": "临汾市",
			"center": [111.517973, 36.08415],
			"centroid": [111.381988, 36.228828],
			"childrenNum": 17,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 9,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[110.410545, 36.899481],
						[110.402213, 36.886589],
						[110.376047, 36.882291],
						[110.377696, 36.873197],
						[110.41406, 36.865721],
						[110.424518, 36.855566],
						[110.419528, 36.835001],
						[110.407247, 36.824904],
						[110.422912, 36.819169],
						[110.419571, 36.798345],
						[110.403298, 36.772963],
						[110.388501, 36.764916],
						[110.413192, 36.763107],
						[110.43068, 36.752438],
						[110.416664, 36.731908],
						[110.42122, 36.721548],
						[110.439966, 36.737151],
						[110.451812, 36.726541],
						[110.436755, 36.711248],
						[110.438143, 36.685962],
						[110.420048, 36.683963],
						[110.402951, 36.697263],
						[110.394446, 36.676844],
						[110.422348, 36.661791],
						[110.44743, 36.621115],
						[110.471513, 36.595736],
						[110.496812, 36.582231],
						[110.489869, 36.550709],
						[110.501281, 36.536132],
						[110.503408, 36.48819],
						[110.473075, 36.448487],
						[110.492125, 36.423177],
						[110.487005, 36.393847],
						[110.469257, 36.369961],
						[110.45997, 36.327877],
						[110.473553, 36.310497],
						[110.473943, 36.248161],
						[110.454156, 36.223666],
						[110.459493, 36.194575],
						[110.447083, 36.164341],
						[110.450467, 36.132272],
						[110.470428, 36.09459],
						[110.467955, 36.074641],
						[110.492212, 36.034665],
						[110.499676, 36.008086],
						[110.49295, 35.995928],
						[110.514256, 35.975701],
						[110.515514, 35.959252],
						[110.503234, 35.94324],
						[110.516686, 35.921739],
						[110.511696, 35.879729],
						[110.551227, 35.875376],
						[110.55075, 35.838076],
						[110.56264, 35.826775],
						[110.571275, 35.800695],
						[110.563812, 35.787684],
						[110.574009, 35.769805],
						[110.57722, 35.73688],
						[110.616752, 35.762476],
						[110.62747, 35.784399],
						[110.656153, 35.788189],
						[110.675637, 35.768415],
						[110.696813, 35.765319],
						[110.67976, 35.742569],
						[110.68475, 35.729105],
						[110.746586, 35.744338],
						[110.759561, 35.732456],
						[110.801826, 35.723226],
						[110.84548, 35.719117],
						[110.87412, 35.709696],
						[110.898681, 35.690851],
						[110.91248, 35.690408],
						[110.953834, 35.719053],
						[110.949408, 35.7499],
						[110.951708, 35.786041],
						[110.976356, 35.807832],
						[111.002652, 35.734099],
						[111.057025, 35.741052],
						[111.061798, 35.755777],
						[111.101113, 35.812442],
						[111.126932, 35.819703],
						[111.144854, 35.811494],
						[111.16642, 35.77606],
						[111.183821, 35.764813],
						[111.188508, 35.730496],
						[111.196015, 35.71937],
						[111.23598, 35.703626],
						[111.252774, 35.680289],
						[111.265184, 35.679783],
						[111.291438, 35.704637],
						[111.32203, 35.71311],
						[111.336784, 35.709633],
						[111.347589, 35.670737],
						[111.313308, 35.677379],
						[111.291741, 35.669535],
						[111.302026, 35.650111],
						[111.290266, 35.629796],
						[111.268135, 35.629923],
						[111.266269, 35.598017],
						[111.283974, 35.571925],
						[111.271303, 35.55894],
						[111.296254, 35.536194],
						[111.361866, 35.551211],
						[111.410336, 35.572179],
						[111.423051, 35.55951],
						[111.482891, 35.567048],
						[111.506584, 35.574776],
						[111.557398, 35.567302],
						[111.570806, 35.554379],
						[111.585213, 35.55875],
						[111.586775, 35.580982],
						[111.59962, 35.597194],
						[111.604089, 35.633151],
						[111.612638, 35.649984],
						[111.653211, 35.643214],
						[111.675559, 35.655996],
						[111.683413, 35.639923],
						[111.722815, 35.644859],
						[111.764646, 35.611376],
						[111.823358, 35.599347],
						[111.860503, 35.599663],
						[111.839934, 35.584592],
						[111.84384, 35.553302],
						[111.908843, 35.541137],
						[111.902768, 35.52333],
						[111.853256, 35.511477],
						[111.845445, 35.482187],
						[111.833989, 35.472485],
						[111.838893, 35.458214],
						[111.814245, 35.430364],
						[111.838155, 35.435123],
						[111.848483, 35.424463],
						[111.851303, 35.399331],
						[111.89179, 35.387905],
						[111.94933, 35.393618],
						[111.972632, 35.420339],
						[111.974064, 35.431443],
						[111.9712, 35.456882],
						[111.946987, 35.506533],
						[111.965082, 35.525484],
						[111.966731, 35.566668],
						[111.948375, 35.576422],
						[111.94217, 35.626948],
						[111.957575, 35.640113],
						[111.964431, 35.664853],
						[111.989339, 35.670484],
						[112.027395, 35.701096],
						[112.037028, 35.722973],
						[112.025139, 35.752112],
						[112.053735, 35.773027],
						[112.048484, 35.790337],
						[112.030736, 35.810989],
						[112.035596, 35.855939],
						[112.04536, 35.864206],
						[112.065885, 35.845588],
						[112.129544, 35.820082],
						[112.186737, 35.817304],
						[112.213511, 35.829995],
						[112.209779, 35.847986],
						[112.183439, 35.873546],
						[112.159312, 35.86976],
						[112.144168, 35.910387],
						[112.163131, 35.907108],
						[112.191033, 35.917136],
						[112.240371, 35.896952],
						[112.278124, 35.903828],
						[112.327332, 35.894997],
						[112.34738, 35.900863],
						[112.381878, 35.895817],
						[112.411994, 35.905531],
						[112.428527, 35.921424],
						[112.463328, 35.940719],
						[112.516963, 35.962719],
						[112.532324, 35.984902],
						[112.522127, 36.031075],
						[112.503337, 36.032586],
						[112.490536, 36.068725],
						[112.49813, 36.082508],
						[112.564783, 36.114472],
						[112.570381, 36.132021],
						[112.561008, 36.162455],
						[112.567213, 36.188416],
						[112.560964, 36.224796],
						[112.574416, 36.263295],
						[112.565521, 36.27265],
						[112.540699, 36.255571],
						[112.506939, 36.259213],
						[112.472224, 36.270829],
						[112.471226, 36.287088],
						[112.454563, 36.304849],
						[112.461029, 36.313634],
						[112.443671, 36.342681],
						[112.39468, 36.345566],
						[112.354887, 36.34312],
						[112.330761, 36.365133],
						[112.290838, 36.383002],
						[112.257165, 36.410456],
						[112.212816, 36.409642],
						[112.202792, 36.417914],
						[112.180965, 36.458759],
						[112.161785, 36.512916],
						[112.132798, 36.524556],
						[112.106068, 36.544703],
						[112.087105, 36.580105],
						[112.041585, 36.60205],
						[112.039111, 36.636863],
						[112.022535, 36.654669],
						[112.019367, 36.67828],
						[111.996412, 36.687148],
						[111.981832, 36.677031],
						[111.936138, 36.704943],
						[111.874259, 36.70563],
						[111.837157, 36.696514],
						[111.842711, 36.711685],
						[111.818498, 36.712809],
						[111.786647, 36.703195],
						[111.759526, 36.717303],
						[111.718258, 36.711311],
						[111.672217, 36.69539],
						[111.68567, 36.661728],
						[111.676861, 36.651483],
						[111.658331, 36.656918],
						[111.622966, 36.687772],
						[111.572195, 36.716492],
						[111.546462, 36.745512],
						[111.538218, 36.768347],
						[111.551583, 36.778763],
						[111.54234, 36.788243],
						[111.518951, 36.789303],
						[111.515306, 36.779013],
						[111.49912, 36.803957],
						[111.490354, 36.7792],
						[111.461932, 36.771902],
						[111.434767, 36.785748],
						[111.414676, 36.804019],
						[111.365684, 36.778264],
						[111.356919, 36.754559],
						[111.323375, 36.753811],
						[111.325241, 36.739959],
						[111.274688, 36.738523],
						[111.277248, 36.758552],
						[111.243878, 36.758116],
						[111.229298, 36.741207],
						[111.211637, 36.748944],
						[111.200528, 36.806264],
						[111.181087, 36.82746],
						[111.18508, 36.840361],
						[111.135264, 36.846032],
						[111.114565, 36.8355],
						[111.096383, 36.836622],
						[111.06783, 36.85513],
						[111.084189, 36.908074],
						[111.09469, 36.912744],
						[111.080804, 36.931109],
						[111.042184, 36.929117],
						[111.020357, 36.917475],
						[111.014108, 36.899792],
						[110.999224, 36.89892],
						[111.00313, 36.923514],
						[110.989547, 36.928245],
						[110.99367, 36.908011],
						[110.971062, 36.89618],
						[110.937432, 36.906393],
						[110.926887, 36.895433],
						[110.896728, 36.885966],
						[110.89521, 36.875938],
						[110.838928, 36.85893],
						[110.791412, 36.864475],
						[110.776528, 36.876685],
						[110.765766, 36.900166],
						[110.725323, 36.910813],
						[110.717816, 36.932602],
						[110.661751, 36.933723],
						[110.648733, 36.92339],
						[110.647128, 36.892568],
						[110.630334, 36.871889],
						[110.604819, 36.874941],
						[110.574313, 36.861485],
						[110.558214, 36.872325],
						[110.539251, 36.860426],
						[110.534998, 36.869958],
						[110.499719, 36.879613],
						[110.469777, 36.876748],
						[110.461793, 36.887212],
						[110.432763, 36.885406],
						[110.410545, 36.899481]
					]
				]
			]
		}
	}, {
		"type": "Feature",
		"properties": {
			"adcode": 141100,
			"name": "吕梁市",
			"center": [111.134335, 37.524366],
			"centroid": [111.268548, 37.684104],
			"childrenNum": 13,
			"level": "city",
			"parent": {
				"adcode": 140000
			},
			"subFeatureIndex": 10,
			"acroutes": [100000, 140000]
		},
		"geometry": {
			"type": "MultiPolygon",
			"coordinates": [
				[
					[
						[112.33011, 37.458923],
						[112.317439, 37.476414],
						[112.290361, 37.48729],
						[112.313446, 37.495508],
						[112.295698, 37.521452],
						[112.273741, 37.524046],
						[112.294093, 37.548745],
						[112.290448, 37.566894],
						[112.255689, 37.561709],
						[112.233776, 37.570042],
						[112.227006, 37.59232],
						[112.174109, 37.6355],
						[112.175932, 37.674525],
						[112.118652, 37.698558],
						[112.10277, 37.716178],
						[112.075909, 37.717779],
						[112.034642, 37.71125],
						[112.023316, 37.723939],
						[111.996238, 37.722091],
						[111.974975, 37.713159],
						[111.935053, 37.740752],
						[111.921384, 37.757931],
						[111.912272, 37.792953],
						[111.819843, 37.796091],
						[111.808474, 37.817133],
						[111.767814, 37.832325],
						[111.767901, 37.841427],
						[111.746855, 37.855509],
						[111.747158, 37.870509],
						[111.706151, 37.878992],
						[111.683456, 37.86387],
						[111.653515, 37.856677],
						[111.642536, 37.861534],
						[111.570242, 37.852373],
						[111.56638, 37.87598],
						[111.547113, 37.885261],
						[111.580093, 37.902713],
						[111.572412, 37.931955],
						[111.545595, 37.955966],
						[111.538044, 37.992429],
						[111.516868, 38.019672],
						[111.52802, 38.037705],
						[111.515913, 38.051258],
						[111.524158, 38.066096],
						[111.546419, 38.074924],
						[111.547547, 38.085344],
						[111.567682, 38.095518],
						[111.625005, 38.10183],
						[111.643187, 38.116106],
						[111.689749, 38.134668],
						[111.716609, 38.15849],
						[111.716305, 38.174959],
						[111.744164, 38.18359],
						[111.766338, 38.179305],
						[111.773368, 38.187752],
						[111.806434, 38.195464],
						[111.81763, 38.214311],
						[111.800272, 38.235416],
						[111.779183, 38.248015],
						[111.777274, 38.292705],
						[111.842364, 38.304072],
						[111.841149, 38.320997],
						[111.821535, 38.336758],
						[111.823314, 38.369062],
						[111.78886, 38.387803],
						[111.793763, 38.402572],
						[111.782828, 38.421],
						[111.79372, 38.429723],
						[111.793546, 38.450827],
						[111.728759, 38.500024],
						[111.725375, 38.514161],
						[111.769029, 38.537129],
						[111.749762, 38.581884],
						[111.719256, 38.602335],
						[111.706325, 38.598318],
						[111.684368, 38.566603],
						[111.609557, 38.542184],
						[111.565599, 38.511907],
						[111.551757, 38.506849],
						[111.522422, 38.524763],
						[111.505846, 38.518975],
						[111.503633, 38.483873],
						[111.468397, 38.510384],
						[111.450779, 38.530306],
						[111.44631, 38.56362],
						[111.432771, 38.570256],
						[111.373625, 38.573057],
						[111.348934, 38.586815],
						[111.346938, 38.60343],
						[111.276206, 38.594301],
						[111.25004, 38.636041],
						[111.248044, 38.657449],
						[111.227519, 38.66736],
						[111.219664, 38.711791],
						[111.202437, 38.72935],
						[111.151753, 38.723882],
						[111.123634, 38.706505],
						[111.071301, 38.686327],
						[111.025087, 38.659334],
						[111.003086, 38.663225],
						[110.985946, 38.684321],
						[110.949495, 38.685841],
						[110.955006, 38.709482],
						[110.945286, 38.729471],
						[110.915388, 38.704256],
						[110.916212, 38.673987],
						[110.896425, 38.657814],
						[110.880803, 38.626916],
						[110.896641, 38.588276],
						[110.919901, 38.582067],
						[110.909443, 38.563194],
						[110.907664, 38.521107],
						[110.871039, 38.510201],
						[110.87425, 38.453693],
						[110.840707, 38.439971],
						[110.825042, 38.449424],
						[110.796836, 38.453449],
						[110.777613, 38.440886],
						[110.758953, 38.400436],
						[110.750318, 38.369489],
						[110.70163, 38.353309],
						[110.668607, 38.313666],
						[110.638839, 38.304745],
						[110.601304, 38.308167],
						[110.577481, 38.297228],
						[110.567977, 38.245752],
						[110.56724, 38.217553],
						[110.558214, 38.210517],
						[110.523933, 38.210517],
						[110.507877, 38.184937],
						[110.510611, 38.147223],
						[110.521199, 38.124438],
						[110.501889, 38.098092],
						[110.508788, 38.061927],
						[110.501411, 38.038993],
						[110.508138, 38.012862],
						[110.527057, 37.988133],
						[110.518161, 37.976409],
						[110.522501, 37.954922],
						[110.547626, 37.940307],
						[110.587982, 37.926611],
						[110.607249, 37.8962],
						[110.650599, 37.840013],
						[110.660753, 37.808028],
						[110.680367, 37.790061],
						[110.735738, 37.770366],
						[110.755699, 37.755037],
						[110.750708, 37.736133],
						[110.716644, 37.728681],
						[110.703496, 37.718642],
						[110.706577, 37.705335],
						[110.739079, 37.689439],
						[110.775399, 37.680873],
						[110.796142, 37.66306],
						[110.793321, 37.650791],
						[110.763683, 37.63957],
						[110.772275, 37.59269],
						[110.794927, 37.566154],
						[110.771103, 37.538373],
						[110.771277, 37.520155],
						[110.759517, 37.503477],
						[110.758346, 37.471161],
						[110.74498, 37.450763],
						[110.648169, 37.438151],
						[110.629683, 37.383659],
						[110.641486, 37.360081],
						[110.695034, 37.34962],
						[110.693949, 37.325907],
						[110.678718, 37.317918],
						[110.690044, 37.287071],
						[110.661925, 37.281991],
						[110.65138, 37.256583],
						[110.584554, 37.180681],
						[110.540292, 37.144626],
						[110.535562, 37.115136],
						[110.498634, 37.089797],
						[110.460491, 37.044749],
						[110.425993, 37.040833],
						[110.424084, 37.023924],
						[110.450337, 37.016463],
						[110.441224, 37.006141],
						[110.412845, 37.019261],
						[110.383207, 37.02181],
						[110.377132, 37.010245],
						[110.405555, 36.991464],
						[110.424257, 36.963719],
						[110.410545, 36.899481],
						[110.432763, 36.885406],
						[110.461793, 36.887212],
						[110.469777, 36.876748],
						[110.499719, 36.879613],
						[110.534998, 36.869958],
						[110.539251, 36.860426],
						[110.558214, 36.872325],
						[110.574313, 36.861485],
						[110.604819, 36.874941],
						[110.630334, 36.871889],
						[110.647128, 36.892568],
						[110.648733, 36.92339],
						[110.661751, 36.933723],
						[110.717816, 36.932602],
						[110.725323, 36.910813],
						[110.765766, 36.900166],
						[110.776528, 36.876685],
						[110.791412, 36.864475],
						[110.838928, 36.85893],
						[110.89521, 36.875938],
						[110.896728, 36.885966],
						[110.926887, 36.895433],
						[110.937432, 36.906393],
						[110.971062, 36.89618],
						[110.99367, 36.908011],
						[110.989547, 36.928245],
						[111.00313, 36.923514],
						[110.999224, 36.89892],
						[111.014108, 36.899792],
						[111.020357, 36.917475],
						[111.042184, 36.929117],
						[111.080804, 36.931109],
						[111.09469, 36.912744],
						[111.084189, 36.908074],
						[111.06783, 36.85513],
						[111.096383, 36.836622],
						[111.114565, 36.8355],
						[111.135264, 36.846032],
						[111.18508, 36.840361],
						[111.181087, 36.82746],
						[111.200528, 36.806264],
						[111.211637, 36.748944],
						[111.229298, 36.741207],
						[111.243878, 36.758116],
						[111.277248, 36.758552],
						[111.274688, 36.738523],
						[111.325241, 36.739959],
						[111.323375, 36.753811],
						[111.356919, 36.754559],
						[111.365684, 36.778264],
						[111.414676, 36.804019],
						[111.414112, 36.832944],
						[111.420577, 36.844287],
						[111.455379, 36.8484],
						[111.464752, 36.865285],
						[111.487838, 36.870331],
						[111.505108, 36.886527],
						[111.543338, 36.892506],
						[111.584736, 36.918783],
						[111.570286, 36.928868],
						[111.541429, 36.931856],
						[111.540127, 36.954448],
						[111.512312, 36.973487],
						[111.498643, 36.991277],
						[111.527152, 36.999052],
						[111.541038, 36.977655],
						[111.552798, 36.982258],
						[111.580353, 36.967016],
						[111.637286, 36.943183],
						[111.647483, 36.964528],
						[111.679334, 36.970252],
						[111.680679, 36.988043],
						[111.735355, 36.997559],
						[111.759482, 37.015468],
						[111.791637, 37.022556],
						[111.826395, 37.044314],
						[111.845836, 37.034182],
						[111.88003, 37.057178],
						[111.906457, 37.098493],
						[111.923337, 37.106256],
						[111.934272, 37.148971],
						[111.951239, 37.179441],
						[111.946422, 37.209402],
						[111.958876, 37.222238],
						[111.956577, 37.240281],
						[111.942083, 37.255406],
						[111.972328, 37.254042],
						[111.980443, 37.271271],
						[112.014811, 37.268668],
						[112.04549, 37.280008],
						[112.049699, 37.298594],
						[112.06927, 37.317485],
						[112.095002, 37.315441],
						[112.102596, 37.298718],
						[112.117611, 37.296116],
						[112.12169, 37.274493],
						[112.139611, 37.266499],
						[112.177364, 37.308071],
						[112.217243, 37.338229],
						[112.259638, 37.394115],
						[112.263847, 37.421331],
						[112.286933, 37.437533],
						[112.327246, 37.448352],
						[112.33011, 37.458923]
					]
				]
			]
		}
	}]
}
